
import {defineComponent, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";
interface HelpResultState {
  searchValue: string | string[],
  searchTopicResult: {
    title:string,
    id:string
  } [],
  searchVideoResult: {
    playBackCounts:number,
    modifyTime:string,
    coverUrl:string,
    id:string,
    title:string
  } [],
  keywordId: string | string[],
  topicStatus: boolean,
  videoStatus: boolean,
  isLoading: boolean,
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const cState = reactive<HelpResultState>({
          searchValue: '',
          keywordId: '',
          searchTopicResult: [],
          searchVideoResult: [],
          topicStatus:true,
          videoStatus:true,
          isLoading:true
        })
        onBeforeMount(() => {
          let query = router.currentRoute.value.query
          if(query.keywordId){
            cState.keywordId = query.keywordId
          }
          if(query.keyword){
            cState.searchValue = decodeURIComponent(query.keyword as string)
          }
        })
        onMounted(() => {
          if(cState.keywordId){
            getTopicsByKeyWordId(cState.keywordId)
          }else{
            getTopicsByKeyWord(cState.searchValue)
          }
        })
        const getTopicsByKeyWordId = async (keywordId)=>{
          Toast.loading({
            message: '查询中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getTopicsByKeyWordId({
            keywordId
          })
          Toast.clear()
          cState.isLoading = false
          if(res.success){
            cState.searchTopicResult = res.result.imageAndTexts
            cState.searchVideoResult = res.result.videos.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return  item
            })
          }
        }
        const getTopicsByKeyWord = async (keyword)=>{
          Toast.loading({
            message: '查询中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getTopicsByKeyWord({
            keyword
          })
          Toast.clear()
          cState.isLoading = false
          if(res.success){
            cState.searchTopicResult = res.result.imageAndTexts
            cState.searchVideoResult = res.result.videos.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return  item
            })
          }
        }
        const goBack = _.throttle(()=>{
          router.go(-1)
        },1000)
        const goToSearchPage= ()=>{
          router.replace({
            path:'/help/search',
            query:{
              keyword: encodeURIComponent(cState.searchValue as string)
            }
          })
        }
        const goToTopicDetail = (id)=>{
          router.push({
            path:"/help/topic/detail/"+id
          })
        }
        const goVideoDetail = (id)=>{
          router.push({
            path:"/help/video/detail/"+id
          })
        }
        const showAllResult = ()=>{
          cState.topicStatus = true
          cState.videoStatus = true
        }
        return {
          ...toRefs(cState),
          goBack,
          goToSearchPage,
          goToTopicDetail,
          goVideoDetail,
          showAllResult
        }
      }
    }
)
