<template>
<div class="login-container">
  <transition name="tipfade">
    <div class="login-recommend" v-if="isRecommandPay">
      <div class="l-r-p1">您的好友<span style="font-weight: 600">{{recommendpPhone}}</span>邀请您购买建龙云资料</div>
      <div class="l-r-p2">限时优惠，即刻购买可多送一个月会员</div>
    </div>
  </transition>
  <div class="login-bg">

  </div>
  <div class="login-form-c">
    <div class="login-t">登录</div>
    <div class="login-form">
      <div class="l-f-i">
        <div class="l-f-icon"><img src="../assets/login/phone.png" alt=""></div>
        <div class="l-f-input"><input   placeholder="请输入手机号" type="text" v-model="userNameOrEmailAddressOrPhoneNumber"></div>
      </div>
      <div class="l-f-i captcha-img">
        <div class="l-f-icon"><img src="../assets/login/code-img4.png" alt=""></div>
        <div class="l-f-input"><input maxlength="4" placeholder="请输入图形验证码" type="text" v-model="captchaImg"></div><div class="captch-img"> <img  @click="getGetCaptchaImg()" :src="captchaImgUrl" alt=""></div>
      </div>
      <div class="l-f-i">
        <div class="l-f-icon"><img src="../assets/login/code.png" alt=""></div>
        <div class="l-f-input"><input  type="text" placeholder="请输入短信验证码"  v-model="captcha"></div>
        <div class="l-f-btn">
          <van-button size="mini" type="primary" class="code-btn" v-if="!timerCountShow" @click="getPhoneCode()">发送验证码</van-button>
          <div class="login-timer" v-if="timerCountShow">{{timerCount+'s'}}</div>
        </div>
      </div>
      <div class="l-f-tips">{{errorFormTip==''?'':'*'+errorFormTip}}</div>
      <div class="l-f-logindesc">验证即登录，未注册将自动创建新账号</div>
      <div class="l-f-btnc">
        <van-button class="l-f-btn" round block type="primary" @click="onSubmit" >
          登录/注册
        </van-button>
      </div>
    </div>
  </div>


</div>
</template>

<script lang="ts">
import {defineComponent, inject, onBeforeMount, onBeforeUnmount, ref} from "@vue/runtime-core";
import {useRoute, useRouter} from 'vue-router';
import {toRefs,reactive} from "@vue/reactivity";
import {Toast,Form} from "vant"
import helper from "@/utils/helper";
import loginApis from '../api/login/index'
import  _ from "lodash"
import Utils from "@/utils/helper";
import payApis from "@/api/pay";
interface captchRes{
  captchaBaBase64String?:string,
  captchaId?: string
}
export default defineComponent({
  name: "Login",
  setup(){
    const router = useRouter();
    const route = useRoute()
    const getUserData = inject('getUserData')
    const userData = inject('userData')
    const loginFormRef = ref<any>(null)
    const formState = reactive({
      userNameOrEmailAddressOrPhoneNumber:'',
      captcha:"",
      captchaImg:""
    })
    const cState = reactive({
      timerCount:60,
      timerCountShow:false,
      captchaImgUrl:'',
      recommendCode:'',
      recommendpPhone:'',
      isRecommandPay: false,
      errorFormTip:""
    })
    let timer:any = null
    let errorTimer:any = null
    let  captchaImgRes:captchRes = {}
    onBeforeMount(()=>{
      Utils.userLogout()
      // if(route.query.s!==undefined){
      //   let encryptionData = ''
      //   try {
      //     encryptionData = Utils.decryptCode(route.query.s)
      //   }catch (e) {
      //     encryptionData = ''
      //   }
      //   if(!!encryptionData && encryptionData.length == 6){
      //     cState.recommendCode = encryptionData
      //     getUserPhoneWithCode()
      //   }
      // }
      getGetCaptchaImg()
    })
    onBeforeUnmount(()=>{
      clearInterval(timer)
    })
    const getUserPhoneWithCode = async ()=>{
      let res = await payApis.getUserPhoneWithRecommendCode({
        code:cState.recommendCode
      })
      console.log(res)
      if(res.success){
        cState.recommendpPhone = res.result
        cState.isRecommandPay = true
      }else{
        // 获取不到对应的用户信息.无效推荐码
        cState.isRecommandPay = false
      }
    }
    const getGetCaptchaImg = async ()=>{
      let res = await loginApis.getCaptchaImg({})
      console.log(res)
      if(res.success){
         cState.captchaImgUrl = 'data:image/png;base64,'+res.result.captchaBaBase64String
         captchaImgRes = res.result
      }
    }
    const throwLoginError = (error)=>{
      cState.errorFormTip = error
      errorTimer = setTimeout(()=>{
        cState.errorFormTip = ''
      },5000)
    }
    const onSubmit = async (values:any) => {
      if(!/^1[3-9]\d{9}$/.test(formState.userNameOrEmailAddressOrPhoneNumber)){
        throwLoginError('手机号格式错误!')
        return
      }
      if(!/^[0-9]{6}$/.test(formState.captcha)){
        throwLoginError('请输入正确的短信验证码!')
        return
      }
      let res = await loginApis.loginWithPhoneCode({
        userNameOrEmailAddressOrPhoneNumber:formState.userNameOrEmailAddressOrPhoneNumber,
        captcha:formState.captcha
      });
      console.log(res)
      if(res.success){
        Toast.success('登录成功!');
        localStorage.setItem('access_token','Bearer '+res.result.accessToken);
        if(Utils.getBrowserInfo().isWeixin){
          loginApis.bindingWeiXinByLogin({
            openId:userData['openId']
          });
        }

        (getUserData as any)();
        setTimeout(()=>{
          let params:{
            [name:string]:any
          } = {

          }
          if(route.query.s!==undefined){
            params.s = route.query.s
          }
          if(route.query.redirect_url!==undefined){
            router.replace({
              path:decodeURIComponent(route.query.redirect_url as string) ,
              query:params
            })
          }else{
            router.replace({
              path:'/pay',
              query:params
            })
          }
        },1000)
      }else{
        Toast.fail(_.get(res,'result.error.message','登录失败!'));
      }
    };
    const getPhoneCode = async ()=>{
      if(!/^1[3-9]\d{9}$/.test(formState.userNameOrEmailAddressOrPhoneNumber)){
        throwLoginError('手机号格式错误!')
        return
      }
      if(!/^[0-9A-Za-z]{4}$/.test(formState.captchaImg)){
        throwLoginError('请输入4位图形验证码!')
        return
      }
      let res = await loginApis.getAuthGetcaptcha({
        phoneNumber: formState.userNameOrEmailAddressOrPhoneNumber,
        captchaId: captchaImgRes.captchaId,
        captcha: formState.captchaImg,
        captchaType:"1"
      })
      getGetCaptchaImg()
      if(res.success){
        cState.timerCount = 60
        cState.timerCountShow = true
        timer = setInterval(()=>{
          cState.timerCount-=1
          if(cState.timerCount==0){
            cState.timerCountShow = false
            clearInterval(timer)
          }
        },1000)
        Toast.success('发送成功!');
        if(process.env.VUE_APP_MODE !=='prod'){
         setTimeout(()=>{ Toast({
           message: '验证码:'+res.result+'正式环境不会出来',
           duration: 5000
         })},2000)
        }

      }else{
        console.log('1111');
        Toast.fail(_.get(res,'result.error.message','发送失败!'));
      }
    }
    const validatorMessage = async (val:string)=>{
      if(!/^1[3-9]\d{9}$/.test(val)){
        return '请输入合法手机号！'
      }
    }
    return {
      ...toRefs(formState),
      ...toRefs(cState),
      onSubmit,
      getGetCaptchaImg,
      getPhoneCode,
      validatorMessage,
      loginFormRef
    }
  }
})
</script>

<style scoped lang = "scss">
 .login-container{
   width: 100%;
   position: relative;
   font-family: "Noto Sans SC";
   .login-recommend{
     position: fixed;
     height: 50px;
     background-color: #f3732a;
     width: 300px;
     top:20px;
     padding-left:14px;
     padding-top: 6px;
     color: #fcfcfc;
     left: 50%;
     transform: translate(-50%,0);
     font-family: "Noto Sans SC";
     font-weight: 300;
     border-radius: 6px;
     .l-r-p1{
       font-size: 13px;
       font-family: Noto Sans SC;
     }
     .l-r-p2{
       font-size: 12px;
       font-family: Noto Sans SC;
     }
   }
   .tipfade-enter-active{
     transition: all 0.25s ease-out;
   }
   .tipfade-leave-active {
     transition: all 0.25s ease-in;
   }

   .tipfade-enter-from{
     opacity: 0;
     top:-20px;
   }
   .tipfade-enter-to{
     opacity: 1;
     top:20px;
   }
   .login-bg{
      height: 270px;
      width: 100% ;
      background-image: url("../assets/login/login_bg.png");
     @include z-b-common;
   }
   .login-form-c{
     height: 333px;
     width: 350px;
     position: absolute;
     left: 50%;
     transform: translate(-50%,0);
     top:230px;
     background-color: #ffffff;
     box-shadow: 0px 3px 22px 0px rgba(248,78,0,0.15);
     border-radius: 16px;
     padding: 10px 10px;
   }
   .login-t{
     font-size: 24px;
     color: #F84E00;
     padding-left:20px ;
   }
   .login-form{
     padding: 20px 20px 0 20px;
     box-sizing: content-box;
     .l-f-tips{
       color: #f85757;
       margin-top: 2px;
       height: 12px;
       font-size: 12px;
     }
     .l-f-logindesc{
       font-size: 13px;
       color: #555454;
       margin-top: 6px;
     }
     .l-f-btnc{
       margin-top: 12px;
       .l-f-btn{
         background-image: linear-gradient(-84deg,   #f15504 0%,  #f39868 100%);
         border: none !important;
         background-blend-mode: normal,
         normal;
         font-size: 14px;
         border-radius: 23px;
       }
     }
   }
   .l-f-i{
     display: flex;
     background-color: #f8f8f8;
     border-radius: 20px;
     height: 40px;
     width: 100%;
     margin-bottom: 12px;
     align-items: center;
     padding-left: 40px;
     position: relative;
     &:nth-child(3){
       margin-bottom: 0;
     }
     &.captcha-img{
       width: 170px;
       .l-f-icon{
         width: 16px;
         height: 16px;
         left: 15px;
       }
       .l-f-input input{
         width: 120px;
       }
       .captch-img{
         position: absolute;
         width: 80px;
         height: 25px;
         right: -90px;
         img{
           width: 100%;
           height: 100%;
         }
       }
     }
     .l-f-input{
       input{
         background-color: transparent;
         border: 0;
         resize: none;
         font-size: 13px;
         width: 180px;
         color: #323233 ;
       }
       .login-captcha-input{
         width: 100px;
       }
     }
     .l-f-btn{
       width: 100px;
       height: 40px;
       right: 0px;
       top:50%;
       transform: translate(0,-50%);
       display: flex;
       align-items: center;
       position: absolute;
     }
     .l-f-icon{
       width: 18px;
       height: 18px;
       position: absolute;
       left: 14px;
       top:50%;
       transform: translate(0,-50%);
       img{
         width: 100%;
         height: 100%;
       }
     }
   }
   .code-btn{
     border-radius: 15px;
     height: 28px;
     line-height: 28px;
     font-size: 12px;
     width: 86px;
   }
   .login-timer{
     border-radius: 15px;
     width: 86px;
     height: 28px;
     color: white;
     font-size: 12px;
     border-radius: 15px;
     text-align: center;
     background-color: #dedede;
     line-height: 28px;
   }
 }
</style>
