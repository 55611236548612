<template>
  <div class="hi-container">
    <div class="hi-top">
      <div class="search-c">
        <div class="hi-top-left">
          <van-field
              v-model="searchValue"
              label=""
              left-icon="search"
              placeholder="请输入关键词"
              center
          >
          </van-field>
        </div>
        <div class="hi-top-right">
          <van-button size="small" type="default">搜索</van-button>
        </div>
      </div>
      <div class="search-mask" @click="goToSearchPage()">

      </div>
    </div>
    <div class="hi-bottom">
      <div class="hi-category-c">
        <div class="hi-category-title">
          问题分类
        </div>
        <div class="hi-category-listc" ref="categoryContainer" @scroll="categoryScrollHandler($event)">
          <div class="hi-category-list" ref="categoryList">
            <div class="hi-category-i" v-for="(item,index) in topicCategoryList" :key="index" @click="goToTopicList(item.id)">
              <img :src="item.icon" alt="" class="hi-category-icon">
              <span class="hi-category-text">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="list-indicator-c">
          <div class="list-indicator">
            <div class="list-indicator-active" :style="{width:indicatorWidth,left:indicatorLeft}">

            </div>
          </div>
        </div>
      </div>
      <div class="hi-video-c">
        <div class="hi-video-title">
          视频教程
        </div>
        <div class="hi-video-tabs">
          <div class="hi-video-tab"  v-for="(item,index) of videoCategoryList" :key="index" @click="videoTabSelectChanged(index)" :class="{active:videoCategoryIndex==index}">
            {{item.name}}
          </div>
        </div>
        <div class="hi-video-list" v-if="videoList.length!=0">
            <div class="hi-video-i" v-for="(item,index) of videoList" :key="index" @click="goToVideoDetail(item.id)">
              <div class="hi-video-cover">
                <img v-lazy="item.coverUrl">
              </div>
              <div class="hi-video-text">
                 {{item.title}}
              </div>
            </div>
        </div>
        <div class="hi-container-empty" v-if="videoList.length==0">
          <img src="../../assets/help/blank_common.png" alt="">
          <span>暂无相关视频!</span>
        </div>
        <div class="hi-more" v-if="hasMoreVideo" @click="goToVideoCenter()">
          <span class="hi-more-btn">更多视频</span>
        </div>
      </div>
      <div class="hi-contact-c">
        <div class="hi-contact-title">
          联系我们
        </div>
        <div class="hi-qrcode-c">
          <img src="../../assets/help/qrcode.jpg" alt="">
        </div>
        <div class="hi-contact-desc">
          关注“建龙软件”微信公众号，工作日9:00-18:00在线客服为您解答。也可以点击屏幕右下角的“留言板”给我们留言。
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import {Toast} from "vant";

interface HelpIndexState {
  searchValue: string,
  indicatorWidth: string,
  indicatorLeft: string,
  videoList: {
    coverUrl:string,
    title:string
  }[],
  hasMoreVideo:boolean,
  videoCategoryList: {
    id: string,
    name: string,
    icon: string
  }[],
  videoCategoryIndex:number,
  topicCategoryList: {
    id: string,
    name: string,
    icon: string
  }[]
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const categoryContainer = ref<any>(null)
        const categoryList = ref<any>(null)
        const cState = reactive<HelpIndexState>({
          searchValue: '',
          indicatorWidth: '',
          indicatorLeft: '',
          topicCategoryList: [],
          videoList:[],
          hasMoreVideo:false,
          videoCategoryIndex:0,
          videoCategoryList:[]
        })
        onBeforeMount(() => {
          //window.location.href = 'https://cb32.com'
          //

        })
        onMounted(() => {
          getTopicCategoryInfo()
          getVideoCategoryInfo()
        })
        const getVideoCategoryInfo = async () => {
          let res = await helpApis.getVideoCategoryInfo({})
          if (res.success) {
            cState.videoCategoryList = res.result
            cState.videoCategoryIndex = 0
            if(res.result.length!=0){
              getVideoList(res.result[0].id)
            }
          }
        }
        const getVideoList = async (id) => {
          Toast.loading({
            message: '',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getVideoList({
            categoryId:id
          })
          Toast.clear()
          if (res.success) {
            let videoList = res.result.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return item
            })
            if(videoList.length>6) {
              cState.hasMoreVideo = true
              cState.videoList = videoList.splice(0,6)
            }else{
              cState.videoList = videoList
            }
          }
        }
        const getTopicCategoryInfo = async () => {
          let res = await helpApis.getTopicCategoryInfo({})
          if (res.success) {
            cState.topicCategoryList = res.result
            // 计算滚动指示器
            await nextTick(() => {
              let categoryContainerEl: HTMLElement = categoryContainer.value as HTMLElement;
              let categoryListEl: HTMLElement = categoryList.value as HTMLElement;
              let numBl = ''
              try {
                numBl = Math.round(categoryContainerEl.offsetWidth / categoryListEl.offsetWidth * 100) + '%'
              } catch (e) {
                numBl = '30%'
              }
              cState.indicatorWidth = numBl
            })
          }
        }
        const categoryScrollHandler = _.debounce((e) => {
          let scrollLeft = e.target.scrollLeft
          let categoryListEl: HTMLElement = categoryList.value as HTMLElement;
          cState.indicatorLeft = Math.round(scrollLeft / categoryListEl.offsetWidth * 100) + '%'
        }, 100)
        const goToSearchPage = ()=>{
          router.push({
            path:'/help/search'
          })
        }
        const videoTabSelectChanged =(index)=>{
          cState.videoCategoryIndex = index
          getVideoList(cState.videoCategoryList[index]['id'])
        }
        const goToVideoCenter = ()=>{
          // 跳转获取更多视频

          router.push({
            path:'/help/video/center'
          })
        }
        const goToVideoDetail = (id)=>{
          router.push({
            path:'/help/video/detail/'+id
          })
        }
        const goToTopicList = (id)=>{
          router.push({
            path:'/help/topic/category/'+id
          })
        }
        return {
          ...toRefs(cState),
          categoryContainer,
          categoryScrollHandler,
          categoryList,
          goToSearchPage,
          videoTabSelectChanged,
          goToVideoCenter,
          goToVideoDetail,
          goToTopicList
        }
      }
    }
)
</script>

<style scoped lang="scss">
.hi-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .hi-top {
    height: 58px;
    width: 100%;
    padding: 12px;
    background-color: #f84e00;
    display: flex;
    align-items: center;
    position: relative;
    .search-mask{
      background-color: rgba(0,0,0,0);
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
    }
    .search-c {
      height: 28px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .hi-top-left {
      flex: 1;
      height: 100%;

      .van-cell {
        height: 100%;
        padding: 0px 12px;
        border-radius: 4px;
      }
    }

    .hi-top-right {
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .van-button {
        background-color: #f84e00;
        height: 100%;
        border: none;
        color: white;
        font-size: 14px;
      }
    }
  }

  .hi-bottom {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    background-color: #e5e5e5;

    .hi-category-c {
      padding: 12px 10px;
      background-color: white;

      .hi-category-title {
        color: #333333;
        font-size: 16px;
        padding-left: 5px;
        font-family: "Noto Sans SC";
      }

      .hi-category-listc {
        width: 100%;
        height: 60px;
        margin-top: 10px;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }

        .hi-category-list {
          display: inline-flex;
          min-width: 100%;

          .hi-category-i {
            display: flex;
            flex-direction: column;
            width: 71px;
            align-items: center;
            justify-content: center;

            .hi-category-icon {
              height: 32px;
              width: 32px;
            }

            .hi-category-text {
              color: #333333;
              font-size: 13px;
              margin-top: 4px;
            }
          }
        }
      }

      .list-indicator-c {
        display: flex;
        justify-content: center;
        margin-top: 8px;

        .list-indicator {
          width: 30px;
          height: 3px;
          background-color: #dddddd;
          border-radius: 2px;
          position: relative;

          .list-indicator-active {
            width: 50%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            border-radius: 2px;
            background-color: #F84E00;
          }
        }
      }
    }

    .hi-video-c {
      padding: 12px 0px;
      background-color: white;
      margin-top: 10px;

      .hi-video-title {
        color: #333333;
        font-size: 16px;
        padding-left: 15px;
        font-family: "Noto Sans SC";
      }

      .hi-video-tabs {
        display: flex;
        width: 100%;
        margin-top: 10px;
        .hi-video-tab {
          flex: 1;
          height: 42px;
          line-height: 42px;
          text-align: center;
          font-size: 15px;
          font-family: "Noto Sans SC";
          &.active {
            color: #f84e00;
            border-bottom: 2px solid #f84e00;;
          }
        }
      }
      .hi-container-empty{
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          height: 120px;
          width: 140px;
        }
        span{
          margin-top: 10px;
          font-size: 14px;
          color: #737373;
        }
      }
      .hi-more{
        display: flex;
        justify-content: center;
        padding-top: 10px;
        .hi-more-btn{
          width: 70px;
          height: 27px;
          line-height: 27px;
          font-size: 14px;
          color: #999999;
          background-color: #eeeeee;
          border-radius: 4px;
          border: solid 1px #cccccc;
          text-align: center;
        }
      }
      .hi-video-list{
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        .hi-video-i{
          width: 50%;
          display: flex;
          flex-direction: column;
          padding: 0 10px;
          height: 140px;
          .hi-video-cover{
            width: 160px;
            height: 100px;
            img{
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }
          }
          .hi-video-text{
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin-top: 10px;
          }
        }
      }
    }
    .hi-contact-c{
      margin-top:10px ;
      padding: 10px;
      background-color: white;
      .hi-contact-title{
        color: #333333;
        font-size: 16px;
        padding-left: 5px;
        font-family: "Noto Sans SC";
      }
      .hi-qrcode-c{
        margin-top: 10px;
        @include z-f-center;
        img{
          width: 186px;
          height: 186px;
        }
      }
      .hi-contact-desc{
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0px;
        color: #999999;
      }
    }
  }
}

</style>
