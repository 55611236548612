import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/custom.scss'
import "amfe-flexible/index.js"
import FastClick from 'fastclick'
import VConsole from 'vconsole'
import { Uploader,Button,Form,Field,CellGroup,Toast, RadioGroup, Radio,Checkbox, CheckboxGroup,Tab,Tabs,Icon,Lazyload,DropdownMenu} from 'vant';
const app = createApp(App);
app.use(Button)
app.use(Form)
app.use(Icon)
app.use(Field)
app.use(CellGroup)
app.use(Toast)
app.use(RadioGroup)
app.use(Radio)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Tab);
app.use(Tabs);
app.use(Lazyload);
app.use(DropdownMenu);
app.use(Uploader);
app.use(store).use(router).mount('#app')
//FastClick的ios点击穿透解决方案
FastClick.prototype.focus = function (targetElement) {
    let length;
    if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
        length = targetElement.value.length;
        targetElement.focus();
        targetElement.setSelectionRange(length, length);
    } else {
        targetElement.focus();
    }
};

FastClick.attach(document.body)
if(process.env["NODE_ENV"]!='production'){
    const vConsole = new VConsole();
}else{

}
