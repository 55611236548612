
import {defineComponent, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import {Toast} from "vant";

interface HelpIndexState {
  searchValue: string,
  indicatorWidth: string,
  indicatorLeft: string,
  videoList: {
    coverUrl:string,
    title:string
  }[],
  hasMoreVideo:boolean,
  videoCategoryList: {
    id: string,
    name: string,
    icon: string
  }[],
  videoCategoryIndex:number,
  topicCategoryList: {
    id: string,
    name: string,
    icon: string
  }[]
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const categoryContainer = ref<any>(null)
        const categoryList = ref<any>(null)
        const cState = reactive<HelpIndexState>({
          searchValue: '',
          indicatorWidth: '',
          indicatorLeft: '',
          topicCategoryList: [],
          videoList:[],
          hasMoreVideo:false,
          videoCategoryIndex:0,
          videoCategoryList:[]
        })
        onBeforeMount(() => {
          //window.location.href = 'https://cb32.com'
          //

        })
        onMounted(() => {
          getTopicCategoryInfo()
          getVideoCategoryInfo()
        })
        const getVideoCategoryInfo = async () => {
          let res = await helpApis.getVideoCategoryInfo({})
          if (res.success) {
            cState.videoCategoryList = res.result
            cState.videoCategoryIndex = 0
            if(res.result.length!=0){
              getVideoList(res.result[0].id)
            }
          }
        }
        const getVideoList = async (id) => {
          Toast.loading({
            message: '',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getVideoList({
            categoryId:id
          })
          Toast.clear()
          if (res.success) {
            let videoList = res.result.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return item
            })
            if(videoList.length>6) {
              cState.hasMoreVideo = true
              cState.videoList = videoList.splice(0,6)
            }else{
              cState.videoList = videoList
            }
          }
        }
        const getTopicCategoryInfo = async () => {
          let res = await helpApis.getTopicCategoryInfo({})
          if (res.success) {
            cState.topicCategoryList = res.result
            // 计算滚动指示器
            await nextTick(() => {
              let categoryContainerEl: HTMLElement = categoryContainer.value as HTMLElement;
              let categoryListEl: HTMLElement = categoryList.value as HTMLElement;
              let numBl = ''
              try {
                numBl = Math.round(categoryContainerEl.offsetWidth / categoryListEl.offsetWidth * 100) + '%'
              } catch (e) {
                numBl = '30%'
              }
              cState.indicatorWidth = numBl
            })
          }
        }
        const categoryScrollHandler = _.debounce((e) => {
          let scrollLeft = e.target.scrollLeft
          let categoryListEl: HTMLElement = categoryList.value as HTMLElement;
          cState.indicatorLeft = Math.round(scrollLeft / categoryListEl.offsetWidth * 100) + '%'
        }, 100)
        const goToSearchPage = ()=>{
          router.push({
            path:'/help/search'
          })
        }
        const videoTabSelectChanged =(index)=>{
          cState.videoCategoryIndex = index
          getVideoList(cState.videoCategoryList[index]['id'])
        }
        const goToVideoCenter = ()=>{
          // 跳转获取更多视频

          router.push({
            path:'/help/video/center'
          })
        }
        const goToVideoDetail = (id)=>{
          router.push({
            path:'/help/video/detail/'+id
          })
        }
        const goToTopicList = (id)=>{
          router.push({
            path:'/help/topic/category/'+id
          })
        }
        return {
          ...toRefs(cState),
          categoryContainer,
          categoryScrollHandler,
          categoryList,
          goToSearchPage,
          videoTabSelectChanged,
          goToVideoCenter,
          goToVideoDetail,
          goToTopicList
        }
      }
    }
)
