<template>
  <div class="pay-status" v-if="status=='success'">
    <div class="pay-s-c">
      <img src="../assets/pay/icon_success.png" alt="">
      <div class="pay-s-p1">支付成功</div>
      <div class="pay-s-p2">请前往https://www.cb32.com登录使用</div>
    </div>
  </div>
  <div class="pay-status" v-if="status=='fail'">
    <div class="pay-s-c">
      <img src="../assets/pay/icon_error.png" alt="">
      <div class="pay-s-p1">支付失败!请返回重新支付</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject,onBeforeMount, onMounted} from "@vue/runtime-core";
import {useRoute} from "vue-router";
import payApis from '../api/pay/index'
import {reactive, toRefs} from "@vue/reactivity";
import {Toast} from "vant";
enum PayStatus {
  'PENDING'=1,
  'SUCEESS' = '2',
  'CLOSE' = '3',
  'REFUND' = '4'
}
export default defineComponent({
  name: "PayStatus",
  setup(){
    const route = useRoute()
    const cState = reactive({
          isLoading: false,
          status:'',
          orderId: ''
        })
    const getUserData = inject('getUserData')
    let orderStatusTimer = null
    let orderStatusInterval = null
    let isLoadingOrderStatus = false
    const fetchOrderStatus = async ()=>{
      if(isLoadingOrderStatus){
        return
      }
      isLoadingOrderStatus = true
      let res = await payApis.getOrderStatus({
        OrderId: cState.orderId
      })
      isLoadingOrderStatus = false
      if(res.success){
        if(res.result.status == PayStatus.SUCEESS){
          (getUserData as any)();
          clearInterval(orderStatusInterval)
          cState.status= 'success'
        }else if(res.result.status == PayStatus.CLOSE && res.result.status == PayStatus.REFUND){
          clearInterval(orderStatusInterval)
          cState.status= 'fail'
        }
      }
    }
    const getOrderStatus = ()=>{
      clearInterval(orderStatusInterval)
      orderStatusInterval = setInterval( ()=>{
        fetchOrderStatus()
      },2*1000)
      clearTimeout(orderStatusTimer)
      orderStatusTimer = setTimeout(()=>{
        clearInterval(orderStatusInterval)
      },30*60*1000)
    }
    onBeforeMount(()=>{
      console.log(route.query)
      if(route.query.orderId){
         cState.orderId = route.query.orderId as string
        fetchOrderStatus()
        getOrderStatus()
      }
    })
    return {
      ...toRefs(cState)
    }
  }
})
</script>

<style scoped lang = "scss">
  .pay-status{
    width: 100%;
    height: 100%;
    @include z-f-center;
    .pay-s-c{
      @include z-f-center;
      position: relative;
      flex-direction: column;
      top:-60px;
      img{
        width: 60px ;
        height: 60px ;
      }
      .pay-s-p1{
        font-size: 18px;
        color: #333333;
        margin-top: 15px;
      }
      .pay-s-p2{
        font-size: 16px;
        color: #666666;
        margin-top: 8px;
      }
    }
  }
</style>
