
import {defineComponent, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";

interface HelpVideoListState {
  categoryId: string,
  sortConditionShow: boolean,
  isLoading: boolean,
  conditions: { name: string, value: string }[],
  conditionIndex: number,
  videoList: {
    title?: string,
    modifyTime?: string,
    content?: string,
    playBackCounts?: string,
    coverUrl?: string,
    videoUrl?: string,
  }[]
  categories: {
    id: "",
    name: ''
  }[]
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const cState = reactive<HelpVideoListState>({
          videoList: [],
          isLoading: true,
          categories: [],
          sortConditionShow: false,
          conditionIndex: 0,
          categoryId: '',
          conditions: [{
            name: '默认排序',
            value: '1'
          }, {
            name: '播放量',
            value: '2'
          }, {
            name: '发布时间',
            value: '3'
          }]
        })
        onBeforeMount(() => {
          getVideoCategories()
        })
        onMounted(() => {

        })
        const getVideoList = async (keywordId) => {
          Toast.loading({
            message: '查询中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getTopicsByKeyWordId({
            keywordId
          })
          Toast.clear()
          cState.isLoading = false
          if (res.success) {

          }
        }
        const getVideoCategories = async () => {
          let res = await helpApis.getVideoCategories({})
          if (res.success) {
            cState.categories = res.result
            cState.categoryId = res.result[0] ? res.result[0].id : ''
            getVideoByCategoryId()
          }
        }
        const getVideoByCategoryId = async () => {
          Toast.loading({
            message: '',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getVideoByCategoryId({
            categoryId: cState.categoryId,
            orderType: cState.conditions[cState.conditionIndex].value
          })
          Toast.clear()
          if (res.success) {
            cState.videoList = res.result.map(item => {
              item.coverUrl = process.env.VUE_APP_BACKEND_URL + '/' + item.coverUrl
              item.videoUrl = process.env.VUE_APP_BACKEND_URL + '/' + item.videoUrl
              return item
            })
          }
        }
        const goBack = () => {
          router.go(-1)
        }
        const goVideoDetail = (id) => {
          router.push({
            path: "/help/video/detail/" + id
          })
        }
        const changeConditionIndex = (index) => {
          cState.conditionIndex = index
          cState.sortConditionShow = false
          getVideoByCategoryId()
        }
        const categoryChanged = (index)=>{
          cState.conditionIndex = 0
          cState.categoryId = cState.categories[index]['id']
          getVideoByCategoryId()
        }
        return {
          ...toRefs(cState),
          goBack,
          goVideoDetail,
          changeConditionIndex,
          categoryChanged
        }
      }
    }
)
