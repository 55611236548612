import request from "./request"
import {AxiosPromise, AxiosRequestConfig} from "axios";
interface responseType {
    success?:string,
    result?:string,
    message?:string,
    httpCode?:string
}
function resolveResponsePromise(r:AxiosPromise<any>):Promise<responseType>{
    return new Promise((resolve)=>{
        resolve(r)
    }).then((res:any)=>{
        console.log(res)
        if(res.success == undefined){
            console.log({success:true,
                result:res.data,
                httpCode:res.status})
            return {
                success:true,
                result:res.data,
                httpCode:res.status
            }
        }else{
            return res
        }
    })
}
const http ={
    /**
     * methods: 请求
     * @param url 请求地址
     * @param params 请求参数
     * @param baseURL
     */
    get(url: string, params: any,baseURL?):Promise<responseType>{
        const config:AxiosRequestConfig = {
            method: 'get',
            url:url,
            headers:{
                'Content-Type':'application/json'
            }
        }
        if(baseURL){
            config.baseURL = baseURL
        }
        if(params) config.params = {...params,t:Math.random()}
        return resolveResponsePromise(request(config))
    },
    post(url: string, params: any,baseURL?):Promise<responseType>{
        const config:AxiosRequestConfig  = {
            method: 'post',
            url:url,
            headers:{
                'Content-Type':'application/json'
            }
        }
        if(baseURL){
            config.baseURL = baseURL
        }
        if(params) config.data = params
        return resolveResponsePromise(request(config))
    },
    upload(url: string, params: any,baseURL?):Promise<responseType>{
        const config:AxiosRequestConfig  = {
            method: 'post',
            url:url,
            headers:{
                'Content-Type':"multipart/form-data"
            }
        }
        if(baseURL){
            config.baseURL = baseURL
        }
        if(params) config.data = params
        return resolveResponsePromise(request(config))
    },
    put(url: string, params: any,baseURL?):Promise<responseType>{
        const config:AxiosRequestConfig = {
            method: 'put',
            url:url,
            headers:{
                'Content-Type':'application/json'
            }
        }
        if(baseURL){
            config.baseURL = baseURL
        }
        if(params) config.params = params
        return resolveResponsePromise(request(config))
    },
    delete(url: string, params: any,baseURL?):Promise<responseType>{
        const config:AxiosRequestConfig = {
            method: 'delete',
            url:url,
            headers:{
                'Content-Type':'application/json'
            }
        }
        if(baseURL){
            config.baseURL = baseURL
        }
        if(params) config.params = params
        return resolveResponsePromise(request(config))
    }
}
//导出
export default http
