<template>
  <div class="ht-container">
    <div class="ht-video-play">
      <video @playing="addVideoPlayCount()" :src="topicInfo.videoUrl" :poster="topicInfo.coverUrl" controls ref="videoRef"></video>
    </div>
    <div class="video-info">
      <div class="ht-title">
        {{topicInfo.title}}
      </div>
      <div class="ht-time">
        <span class="ht-time-num"><van-icon name="play-circle-o" /><span>{{topicInfo.playBackCounts}}次</span></span><span class="ht-time-str">{{topicInfo.modifyTime&&topicInfo.modifyTime.substring(0,10)}}</span>
      </div>
      <div class="ht-keywords">
        <div class="ht-keywords-t">关键词:</div>
        <div class="ht-keywords-c">
          <div class="ht-keywords-i" v-for="(item,index) of topicKeywords" :key="index" @click="goToSearchResult(item)">{{item.text}}</div>
        </div>
      </div>
    </div>
    <div class="video-relation">
      <div class="video-r-t">
        猜你想看
      </div>
      <div class="hv-v-items">
        <template v-for="(item,index) of topicRels" :key="index">
          <div class="hv-v-item" @click="goToOtherTopicPage(item.id)">
            <div class="hv-v-i-cover">
              <img alt="" v-lazy="item.coverUrl">
            </div>
            <div class="hv-v-i-title">
              {{item.title}}
            </div>
            <div class="hv-v-i-bt">
              <div class="hv-v-i-n"><van-icon name="play-circle-o" /><span>{{item.playBackCounts}}次</span></div>
              <span class="hv-v-i-t">{{item.modifyTime.substr(0,10)}}</span>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, inject, onBeforeMount, onMounted, ref, watch, watchEffect} from "@vue/runtime-core";
import {onBeforeRouteUpdate, useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";
interface HelpVideoState {
  topicId:string,
  topicInfo:{
    title?:string,
    modifyTime?:string,
    content?:string,
    playBackCounts?:string,
    coverUrl?:string,
    videoUrl?:string,
  },
  topicKeywords:{
    id?:string,
    text?:string
  }[],
  topicRels:{
    title?:string,
    modifyTime?:string,
    content?:string,
    coverUrl?:string,
    videoUrl?:string
  }[],
  hasAddCount: boolean
}

export default defineComponent(
    {
      name: "HelpVideo",
      setup() {
        const router = useRouter();
        const goToSearchResult = inject('goToSearchResult')
        const videoRef = ref<any>(null)
        const cState = reactive<HelpVideoState>({
          topicId:'',
          topicInfo:{

          },
          topicKeywords:[],
          topicRels:[],
          hasAddCount: false
        })
        onBeforeMount(() => {
          let query = router.currentRoute.value.params
          if(!query.id){
            Toast.fail('图文不存在!')
          }else {
            cState.topicId = query.id as string
            getTopicsInfoById(cState.topicId)
            getTopicsKeyWord(cState.topicId)
            getTopicsRelation(cState.topicId)
          }
        })
        onBeforeRouteUpdate((to) => {
          getTopicsInfoById(to.params.id)
          getTopicsKeyWord(to.params.id)
          getTopicsRelation(to.params.id)
        });
        onMounted(() => {

        })
        watchEffect(() => {
        })
        const getTopicsInfoById = async (id)=>{
          cState.hasAddCount = false
          let res = await helpApis.getTopicsInfoById({
            id
          })
          if(res.success){
            res.result.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+res.result.coverUrl
            res.result.videoUrl = process.env.VUE_APP_BACKEND_URL+'/'+res.result.videoUrl
            cState.topicInfo = res.result
          }else{
            Toast.fail('图文不存在!')
          }
        }
        const getTopicsKeyWord= async (id)=>{
          let res = await helpApis.getTopicsKeyWord({
            id
          })
          if(res.success){
            cState.topicKeywords = res.result
          }
        }
        const addVideoPlayCount= async ()=>{
          if(cState.hasAddCount){
            return
          }
          cState.hasAddCount = true
          let res = await helpApis.addVideoPlayCount({
            id:cState.topicId
          })
        }
        const getTopicsRelation= async (id)=>{
          let res = await helpApis.getTopicsRelation({
            id
          })
          if(res.success){
            cState.topicRels = res.result.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return item
            })
          }
        }
        const goToOtherTopicPage= (id)=>{
          router.push({
            path:'/help/video/detail/'+id
          })
        }
        const goBack = ()=>{
          router.go(-1)
        }
        return {
          ...toRefs(cState),
          videoRef,
          goBack,
          goToOtherTopicPage,
          addVideoPlayCount,
          goToSearchResult
        }
      }
    }
)
</script>

<style scoped lang="scss">
.ht-container {
  width: 100%;
  background-color: #f7f7f7;
  .ht-video-play{
    height: 220px;
    video{
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .video-info{
    padding: 15px;
    background-color: white;
    .ht-title{
      font-size: 17px;
      color: #333333;
      font-family: "Noto Sans SC";
      font-weight: 500;
      width: 100%;
      display: -webkit-box;
      line-height: 1.3;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .ht-time{
      font-size: 14px;
      color: #999999;
      margin-top: 8px;
      display: flex;
      align-items: center;
      .ht-time-str{
        margin-left: 10px;
      }
      .ht-time-num{
        margin-right: 20px;
        display: flex;
        align-items: center;
        i{
          position: relative;
          margin-right: 4px;
        }
      }
    }
    .ht-keywords{
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      .ht-keywords-t{
        font-size: 16px;
        color: #333333;
        margin-right: 15px;
      }
      .ht-keywords-c{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ht-keywords-i{
          background-color: #e5e5e5;
          border-radius: 2px;
          font-size: 14px;
          color: #333333;
          padding: 2px 10px;
          margin-right: 10px;
          margin-bottom: 6px;
        }
      }
    }
  }
  .video-relation{
    margin-top: 10px;
    padding: 15px;
    background-color: white;
    .video-r-t{
      font-size: 17px;
      color: #333333;
      font-family: "Noto Sans SC";
      font-weight: 500;
    }
    .hv-v-items{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .hv-v-item{
        width: 48%;
        padding: 10px 0px;
        .hv-v-i-cover{
          height: 100px;
          img{
            height: 100%;
            width: 100%;
          }
        }
        .hv-v-i-title{
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-top: 10px;
        }
        .hv-v-i-bt{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 6px;
          .hv-v-i-n{
            display: flex;
            align-items: center;

            color: #999999;
            i{
              margin-right: 4px;
              top:1px;
              position: relative;
              font-size: 16px;
            }
            span{
              font-size: 12px;
            }
          }
          .hv-v-i-t{
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }
}

</style>
