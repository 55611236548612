
import {defineComponent, inject, onBeforeMount, onMounted, ref, watch, watchEffect} from "@vue/runtime-core";
import {onBeforeRouteUpdate, useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";
interface HelpVideoState {
  topicId:string,
  topicInfo:{
    title?:string,
    modifyTime?:string,
    content?:string,
    playBackCounts?:string,
    coverUrl?:string,
    videoUrl?:string,
  },
  topicKeywords:{
    id?:string,
    text?:string
  }[],
  topicRels:{
    title?:string,
    modifyTime?:string,
    content?:string,
    coverUrl?:string,
    videoUrl?:string
  }[],
  hasAddCount: boolean
}

export default defineComponent(
    {
      name: "HelpVideo",
      setup() {
        const router = useRouter();
        const goToSearchResult = inject('goToSearchResult')
        const videoRef = ref<any>(null)
        const cState = reactive<HelpVideoState>({
          topicId:'',
          topicInfo:{

          },
          topicKeywords:[],
          topicRels:[],
          hasAddCount: false
        })
        onBeforeMount(() => {
          let query = router.currentRoute.value.params
          if(!query.id){
            Toast.fail('图文不存在!')
          }else {
            cState.topicId = query.id as string
            getTopicsInfoById(cState.topicId)
            getTopicsKeyWord(cState.topicId)
            getTopicsRelation(cState.topicId)
          }
        })
        onBeforeRouteUpdate((to) => {
          getTopicsInfoById(to.params.id)
          getTopicsKeyWord(to.params.id)
          getTopicsRelation(to.params.id)
        });
        onMounted(() => {

        })
        watchEffect(() => {
        })
        const getTopicsInfoById = async (id)=>{
          cState.hasAddCount = false
          let res = await helpApis.getTopicsInfoById({
            id
          })
          if(res.success){
            res.result.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+res.result.coverUrl
            res.result.videoUrl = process.env.VUE_APP_BACKEND_URL+'/'+res.result.videoUrl
            cState.topicInfo = res.result
          }else{
            Toast.fail('图文不存在!')
          }
        }
        const getTopicsKeyWord= async (id)=>{
          let res = await helpApis.getTopicsKeyWord({
            id
          })
          if(res.success){
            cState.topicKeywords = res.result
          }
        }
        const addVideoPlayCount= async ()=>{
          if(cState.hasAddCount){
            return
          }
          cState.hasAddCount = true
          let res = await helpApis.addVideoPlayCount({
            id:cState.topicId
          })
        }
        const getTopicsRelation= async (id)=>{
          let res = await helpApis.getTopicsRelation({
            id
          })
          if(res.success){
            cState.topicRels = res.result.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return item
            })
          }
        }
        const goToOtherTopicPage= (id)=>{
          router.push({
            path:'/help/video/detail/'+id
          })
        }
        const goBack = ()=>{
          router.go(-1)
        }
        return {
          ...toRefs(cState),
          videoRef,
          goBack,
          goToOtherTopicPage,
          addVideoPlayCount,
          goToSearchResult
        }
      }
    }
)
