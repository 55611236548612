
import {defineComponent, h, onUnmounted} from "@vue/runtime-core";
import {reactive, toRefs} from "@vue/reactivity";
import {Toast} from "vant";
import helpApis from "@/api/help";
import router from "@/router";
interface HelpFeedBackState{
  submitForm:{
    name:string,
    phoneNumber:string,
    email:string,
    content:string,
    version:string,
    imageUrls:{
      url:'',
      file:File
    }[],
    videoUrls:{
      url:'',
      file:File
    }[]
  },
  isSuccess:boolean
}
export default defineComponent({
  name: "FeedBack",
  setup(){
    const cState = reactive<HelpFeedBackState>({
      submitForm:{
        name:'',
        phoneNumber:'',
        email:'',
        content:'',
        version: '0',
        imageUrls:[],
        videoUrls:[]
      },
      isSuccess: false
    })
    let timer = null
    onUnmounted(()=>{
      clearTimeout(timer)
    })
    const onSubmit = async ()=>{
       // 处理文件
      Toast.loading({
        message: '提交数据中..',
        forbidClick: true,
        loadingType: 'spinner',
        duration:0
      });
      let imageOrVideoUrls = []
      if(cState.submitForm.imageUrls.length!=0||cState.submitForm.videoUrls.length!=0){
        let formData = new FormData()
        let i = 0
        for(let item of cState.submitForm.imageUrls){
          formData.append('file'+i,item.file)
          i++
        }
        for(let item of cState.submitForm.videoUrls){
          formData.append('file'+i,item.file)
          i++
        }
        let res = await helpApis.uploadFeedbackFile(formData)
        if(res.success){
          imageOrVideoUrls = res.result.data
        }else{
          Toast.clear()
          Toast.fail('提交失败,请重试')
          return
        }
      }
      let params = {
        ...cState.submitForm,
        imageUrls:imageOrVideoUrls
      }
      delete params.videoUrls
      let res = await  helpApis.addFeedback(params)
      Toast.clear()
      if(res.success){
        cState.isSuccess = true
        timer = setTimeout(()=>{
          router.push({
            path:"/help/index"
          })
        },3000)
      }else{
        Toast.fail('提交失败,请重试')
      }
    }
    const onOversize = ()=>{
      Toast('文件大小不能超过10M');
    }
    const afterRead = (file) => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      console.log(cState.submitForm.imageUrls);
    };
    const phoneValidator = (val)=>{
      console.log(/^1[0-9][0-9]{9}$/.test(val)||/^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/.test(val))
      return /^1[0-9][0-9]{9}$/.test(val)||/^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/.test(val)
    }
    return {
      ...toRefs(cState),
      phoneValidator,
      onSubmit,
      onOversize,
      afterRead
    }
  }
})
