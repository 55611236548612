<template>
 <div class="htl-container">
   <div class="htl-list">
     <div class="htl-item" v-for="(item,index) of topicList" :key="index" @click="goToTopicDetail(item.id)">
       <span class="htl-item-icon" v-if="item.fixOnTop">置顶</span>
       <span class="htl-item-title">{{item.title}}</span>
     </div>
   </div>
   <div class="htl-container-empty" v-if="topicList.length==0&&!isLoading">
     <img src="../../assets/help/blank_common.png" alt="">
     <span>该分类暂无相关内容!</span>
   </div>
 </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeMount} from "@vue/runtime-core";
import {reactive, toRefs} from "@vue/reactivity";
import {Toast} from "vant";
import {onBeforeRouteUpdate, useRouter} from "vue-router";
import helpApis from "@/api/help";
import * as _ from 'lodash'
interface HelpTopicListState {
  topicList:{
    id:string,
    title:string,
    fixOnTop:boolean
  } []
  categoryId: string,
  isLoading:boolean
}
export default defineComponent({
  name: "Home",
  setup(){
    const router = useRouter();
    const cState = reactive<HelpTopicListState>({
      categoryId:'',
      topicList:[],
      isLoading:false
    })
    onBeforeMount(() => {
      let query = router.currentRoute.value.params
      if(!query.id){
        Toast.fail('分类不存在!')
      }else {
        cState.categoryId = query.id as string
        getTopicByCategoryId()
      }
    })
    onBeforeRouteUpdate((to) => {
      cState.categoryId = to.params.id as string
      getTopicByCategoryId()
    });
    const getTopicByCategoryId = async ()=>{
      cState.isLoading = true
      let res = await helpApis.getTopicList({
        categoryId:cState.categoryId
      })
      cState.isLoading = false
      if(res.success){
        cState.topicList = res.result
      }
    }
    const goToTopicDetail = (id)=>{
      router.push({
        path:'/help/topic/detail/'+id
      })
    }
    return {
      ...toRefs(cState),
      goToTopicDetail
    }
  }
})
</script>

<style  lang = "scss">
.htl-container{
  background-color: white;
  height: 100%;
  .htl-container-empty{
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      height: 120px;
      width: 140px;
    }
    span{
      margin-top: 10px;
      font-size: 14px;
      color: #737373;
    }
  }
  .htl-list{
    padding: 14px 15px;
    .htl-item{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .htl-item-icon{
        width: 34px;
        height: 19px;
        line-height: 19px;
        font-size: 11px;
        color: #ffffff;
        text-align: center;
        background-image: linear-gradient(308deg,
            #ffe076 0%,
            #ff8416 100%);
        margin-right: 10px;
      }
      .htl-item-title{
        flex: 1;
        font-size: 16px;
        color: #333333;
        width: 34px;
        height: 19px;
        line-height: 19px;
        @include z-t-ellipsis;
      }
    }
  }
}
</style>
