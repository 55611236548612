<template>
  <div class="ht-container">
    <div class="topic-info">
      <div class="ht-title">
        {{topicInfo.title}}
      </div>
      <div class="ht-time">
        <span>发布时间:</span><span class="ht-time-str">{{topicInfo.modifyTime&&topicInfo.modifyTime.substring(0,10)}}</span>
      </div>
      <div class="ht-content ql-editor" v-html="topicInfo.content">

      </div>
      <div class="ht-keywords">
        <div class="ht-keywords-t">关键词:</div>
        <div class="ht-keywords-c">
          <div class="ht-keywords-i" v-for="(item,index) of topicKeywords" :key="index" @click="goToSearchResult(item)">{{item.text}}</div>
        </div>
      </div>
    </div>
    <div class="topic-relation">
      <div class="topic-r-t">
        猜你想看
      </div>
      <div class="topic-r-c">
        <div class="topic-r-i" v-for="(item,index) of topicRels" :key="index" @click="goToOtherTopicPage(item.id)">
           {{item.title}}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, inject, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter,onBeforeRouteUpdate} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";
interface HelpTopicState {
  topicInfo:{
    title?:string,
    modifyTime?:string,
    content?:string
  },
  topicKeywords:{
    id?:string,
    text?:string
  }[],
  topicRels:{
    title?:string,
    modifyTime?:string,
    content?:string
  }[],
}

export default defineComponent(
    {
      name: "HelpTopic",
      setup() {
        const router = useRouter();
        const addTextToSearchList = inject('addTextToSearchList')
        const goToSearchResult = inject('goToSearchResult')
        const cState = reactive<HelpTopicState>({
          topicInfo:{

          },
          topicKeywords:[],
          topicRels:[]
        })
        onBeforeMount(() => {
          let query = router.currentRoute.value.params

          if(!query.id){
           Toast.fail('图文不存在!')
          }else {
            getTopicsInfoById(query.id)
            getTopicsKeyWord(query.id)
            getTopicsRelation(query.id)
          }
        })
        onMounted(() => {

        })
        onBeforeRouteUpdate((to) => {
          getTopicsInfoById(to.params.id)
          getTopicsKeyWord(to.params.id)
          getTopicsRelation(to.params.id)
        });
        const getTopicsInfoById = async (id)=>{
          let res = await helpApis.getTopicsInfoById({
            id
          })
          if(res.success){
             cState.topicInfo = res.result
          }else{
            Toast.fail('图文不存在!')
          }
        }
        const getTopicsKeyWord= async (id)=>{
          let res = await helpApis.getTopicsKeyWord({
            id
          })
          if(res.success){
            cState.topicKeywords = res.result
          }
        }
        const getTopicsRelation= async (id)=>{
          let res = await helpApis.getTopicsRelation({
            id
          })
          if(res.success){
            cState.topicRels = res.result
          }
        }
        const goToOtherTopicPage= (id)=>{
          router.push({
            path:'/help/topic/detail/'+id
          })
        }
        const goBack = ()=>{
          router.go(-1)
        }
        return {
          ...toRefs(cState),
          goBack,
          goToOtherTopicPage,
          goToSearchResult
        }
      }
    }
)
</script>

<style lang="scss">
@import "src/styles/quill";
.ht-container {
  width: 100%;
  background-color: #f7f7f7;
  .ql-editor{
    img{
      max-width: 100% !important;
      height: auto !important;
      margin: 0 !important;
    }
  }
  .topic-info{
    padding: 15px;
    background-color: white;
    .ht-title{
      font-size: 17px;
      color: #333333;
      font-family: "Noto Sans SC";
      font-weight: 500;
      width: 100%;
      display: -webkit-box;
      line-height: 1.3;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .ht-time{
      font-size: 14px;
      color: #999999;
      margin-top: 8px;
      .ht-time-str{
        margin-left: 10px;
      }
    }
    .ht-content{
      background-color: #f8f8f8;
      border-radius: 2px;
      border: solid 1px #cccccc;
      padding: 10px;
      margin-top: 15px;
    }
    .ht-keywords{
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      .ht-keywords-t{
        font-size: 16px;
        color: #333333;
        margin-right: 15px;
      }
      .ht-keywords-c{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ht-keywords-i{
          background-color: #e5e5e5;
          border-radius: 2px;
          font-size: 14px;
          color: #333333;
          padding: 2px 10px;
          margin-right: 10px;
          margin-bottom: 6px;
        }
      }
    }
  }
  .topic-relation{
    margin-top: 10px;
    padding: 15px;
    background-color: white;
    .topic-r-t{
      font-size: 17px;
      color: #333333;
      font-family: "Noto Sans SC";
      font-weight: 500;
    }
    .topic-r-c{
      margin-top: 10px;
      .topic-r-i{
        margin-bottom:8px;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
  }
}

</style>
