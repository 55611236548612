
import {defineComponent, onBeforeMount, onMounted} from "@vue/runtime-core";
import {provide} from 'vue';
import {WEBSAME_URLS,UNWANTED_URLS} from '../src/configs/config'
import {reactive, ref} from "@vue/reactivity";
import userApi from './api/user'
import {CustomModule} from '@/interface'
import moment from 'moment'
import Utils from '@/utils/helper'
import {useRoute} from "vue-router";
import qs from 'qs'
export default defineComponent({
  name:'App',
  setup(){
    const route = useRoute()
    const userData = reactive<CustomModule.UserInfo>({
      id:'',
      phoneNumber:'',
      vipDueTime:'',
      isVip:false,
      recommendCode:'',
      recommandPayUrl:"",
      openId:""
    })
    const getUserData = async ()=>{
      let res = await userApi.getUserInfo({})
      if(res.success){
        let vipDueTime = res.result.vipDueTime;
        res.result.isVip = false;
        if (!!vipDueTime) {
          let localvipDueTime = moment(vipDueTime);
          if (localvipDueTime.isAfter(moment())) {
            res.result.isVip = true;
          }
        }
        userData.id = res.result.id
        userData.phoneNumber = res.result.phoneNumber
        userData.vipDueTime = res.result.vipDueTime
        userData.isVip = res.result.isVip
        userData.recommendCode = res.result.recommendCode
        if(res.result.recommendCode){
          const url = new URL(window.location.href);
          let encryptionStr= Utils.encryptCode(res.result.recommendCode)
          userData.recommandPayUrl=url.origin+'/pay?s='+encryptionStr
        }
      }
    }
    onBeforeMount(()=>{
      // 无法使用router.query
      console.log('应用即将挂载')
      // 判断是否需要定向到电脑网页端以及微信获取code页面
      for(let key in WEBSAME_URLS){
        if(window.location.href.indexOf(key)!=-1){
          Utils.navToWebUrl(WEBSAME_URLS[key])
        }
      }
      let unwantedRedirectWx = false
      for(let item of UNWANTED_URLS){
        let reg = new RegExp(item,'g')
        let isMatch = reg.test(window.location.href)
        if(isMatch){
          unwantedRedirectWx = true
        }
        if(unwantedRedirectWx){
          break
        }
      }
      if(!unwantedRedirectWx){
        let urlParams = qs.parse(window.location.href.split('?')[1])
        if(Utils.getBrowserInfo().isWeixin){
          if(!userData.openId){
            if(urlParams.code){
              // 微信定向回来,do nothing
              getWeixinOpenId(urlParams.code)
            }else{
              let redirect_uri = encodeURIComponent(window.location.href)
              let app_id = process.env.VUE_APP_WEIXIN_APP_ID
              let openUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+app_id+"&redirect_uri="+redirect_uri+"&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
              window.location.href=openUrl
            }
          }
        }else{
          console.log('非微信')
        }
      }

    })
    const getWeixinOpenId = async (code)=>{
     let res = await userApi.getWeixinOpenId({
       code
     })
     if(res.success){
       userData.openId = res.result
     }else{
       if(Utils.getBrowserInfo().isWeixin){

       }
     }
    }
    onMounted(()=>{
      if(Utils.isLogin()){
        getUserData()
      }
    })
    provide('userData',userData)
    provide('getUserData',getUserData)
    return {

    }
  }
})
