<template>
 <div class="hfb-container">
   <van-form @submit="onSubmit" v-if="!isSuccess">
     <van-cell-group inset>
       <van-field
           v-model="submitForm.name"
           name="name"
           required
           label="姓名"
           placeholder="请输入姓名"
           :rules="[{ required: true, message: '请输入姓名' }]"
       />
     </van-cell-group>
     <van-cell-group inset>
       <van-field
           v-model="submitForm.phoneNumber"
           name="phoneNumber"
           label="联系方式"
           required
           placeholder="请输入联系方式(手机/座机)"
           :rules="[{ required: true, message: '请输入联系方式' },
           { validator:phoneValidator, message: '请输入正确的联系方式' }]"
       />
     </van-cell-group>
     <van-cell-group inset>
       <van-field
           v-model="submitForm.email"
           name="email"
           label="邮箱"
           placeholder="邮箱"
           :rules="[]"
       />
     </van-cell-group>
     <van-cell-group inset>
       <van-field name="version" label="版本" required>
         <template #input>
           <van-radio-group v-model="submitForm.version" direction="horizontal">
             <van-radio name="0">免费版</van-radio>
             <van-radio name="1">个人版</van-radio>
             <van-radio name="2">企业版</van-radio>
           </van-radio-group>
         </template>
       </van-field>
     </van-cell-group>
     <van-cell-group inset>
       <van-field
           v-model="submitForm.content"
           rows="8"
           autosize
           required
           name="content"
           label="问题描述"
           type="textarea"
           maxlength="300"
           placeholder="请输入问题描述"
           :rules="[{ required: true, message: '请详细描述您的问题,不超过300个字符。' }]"
           show-word-limit
       />
     </van-cell-group>
     <van-cell-group inset>
       <van-field name="imageUrls" label="上传图片">
         <template #input>
           <van-uploader
               v-model="submitForm.imageUrls"
               accept="image/*"
               :multiple="true"
               :max-size="10 * 1024 * 1024"
               @oversize="onOversize"
               :after-read="afterRead"
           />
         </template>
       </van-field>
       <van-field name="videoUrls" label="上传视频">
         <template #input>
           <van-uploader
               v-model="submitForm.videoUrls"
               accept="video/*"
               :multiple="true"
               :max-size="10 * 1024 * 1024"
               @oversize="onOversize"
               :after-read="afterRead"
           />
         </template>
       </van-field>
     </van-cell-group>
     <div style="margin: 16px;">
       <van-button round block type="primary" native-type="submit">
         提交
       </van-button>
     </div>
   </van-form>
   <div class="submit-status" v-if="isSuccess">
     <div class="submit-status-c">
       <img src="../../assets/pay/icon_success.png" alt="">
       <div class="status-p1">恭喜你,提交成功!</div>
       <div class="status-p2">3s后将跳转到帮助中心首页,<router-link to="/help/index">立刻跳转</router-link></div>
     </div>
   </div>
 </div>
</template>

<script lang="ts">
import {defineComponent, h, onUnmounted} from "@vue/runtime-core";
import {reactive, toRefs} from "@vue/reactivity";
import {Toast} from "vant";
import helpApis from "@/api/help";
import router from "@/router";
interface HelpFeedBackState{
  submitForm:{
    name:string,
    phoneNumber:string,
    email:string,
    content:string,
    version:string,
    imageUrls:{
      url:'',
      file:File
    }[],
    videoUrls:{
      url:'',
      file:File
    }[]
  },
  isSuccess:boolean
}
export default defineComponent({
  name: "FeedBack",
  setup(){
    const cState = reactive<HelpFeedBackState>({
      submitForm:{
        name:'',
        phoneNumber:'',
        email:'',
        content:'',
        version: '0',
        imageUrls:[],
        videoUrls:[]
      },
      isSuccess: false
    })
    let timer = null
    onUnmounted(()=>{
      clearTimeout(timer)
    })
    const onSubmit = async ()=>{
       // 处理文件
      Toast.loading({
        message: '提交数据中..',
        forbidClick: true,
        loadingType: 'spinner',
        duration:0
      });
      let imageOrVideoUrls = []
      if(cState.submitForm.imageUrls.length!=0||cState.submitForm.videoUrls.length!=0){
        let formData = new FormData()
        let i = 0
        for(let item of cState.submitForm.imageUrls){
          formData.append('file'+i,item.file)
          i++
        }
        for(let item of cState.submitForm.videoUrls){
          formData.append('file'+i,item.file)
          i++
        }
        let res = await helpApis.uploadFeedbackFile(formData)
        if(res.success){
          imageOrVideoUrls = res.result.data
        }else{
          Toast.clear()
          Toast.fail('提交失败,请重试')
          return
        }
      }
      let params = {
        ...cState.submitForm,
        imageUrls:imageOrVideoUrls
      }
      delete params.videoUrls
      let res = await  helpApis.addFeedback(params)
      Toast.clear()
      if(res.success){
        cState.isSuccess = true
        timer = setTimeout(()=>{
          router.push({
            path:"/help/index"
          })
        },3000)
      }else{
        Toast.fail('提交失败,请重试')
      }
    }
    const onOversize = ()=>{
      Toast('文件大小不能超过10M');
    }
    const afterRead = (file) => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      console.log(cState.submitForm.imageUrls);
    };
    const phoneValidator = (val)=>{
      console.log(/^1[0-9][0-9]{9}$/.test(val)||/^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/.test(val))
      return /^1[0-9][0-9]{9}$/.test(val)||/^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/.test(val)
    }
    return {
      ...toRefs(cState),
      phoneValidator,
      onSubmit,
      onOversize,
      afterRead
    }
  }
})
</script>

<style lang = "scss">
.hfb-container{
  height: 100%;
  background-color: #f6f6f6;
  .van-radio--horizontal{
    margin-bottom: 10px;
  }
  .van-cell-group{
    margin-bottom: 10px;
  }
  .submit-status{
    position: relative;
    height: 100%;
    width: 100%;
    .submit-status-c{
      display: flex;
      position: absolute;
      top:30%;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .status-p1{
        margin-top: 10px;
        font-size: 16px;
        olor: #333333;
      }
      .status-p2{
        margin-top: 10px;
        font-size: 15px;
        color: #999999;
        a{
          color: #F84E00;
        }
      }
    }
  }
}
</style>
