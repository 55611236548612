<template>
  <div class="hvl-container">
    <div class="hvl-search-c">
      <div class="hvl-search-tab">
        <van-tabs @click="categoryChanged($event)">
          <van-tab v-for="(item,index) in categories" :title="item.name" :key="index">
          </van-tab>
        </van-tabs>
        <div class="hvl-search-line"></div>
      </div>
      <div class="hvl-sort-c">
        <div class="hvl-sort-t" @click="sortConditionShow=!sortConditionShow">
          <span>{{conditions[conditionIndex]['name']}}</span>
          <van-icon :name="sortConditionShow?'arrow-up':'arrow-down'" class="hvl-sort-select"/>
        </div>
        <div class="hvl-sort-conditions" v-if="sortConditionShow">
          <div class="hvl-sort-condition" v-for="(item,index) of conditions" :key="index"
               @click="changeConditionIndex(index)">
            <span>{{ item.name }}</span>
            <van-icon name="success" class="hvl-sort-selected" v-if="index==conditionIndex"/>
          </div>
        </div>
      </div>
    </div>
    <div class="hvl-bottom">
      <div class="hvl-bottom-mask" v-if="sortConditionShow" @click="sortConditionShow=false"></div>
      <div class="hvl-itemlist">
        <div class="hvl-v-items">
          <template v-for="(item,index) of videoList" :key="index">
            <div class="hvl-v-item" @click="goVideoDetail(item.id)">
              <div class="hvl-v-i-cover">
                <img alt="" v-lazy="item.coverUrl">
              </div>
              <div class="hvl-v-i-title">
                {{ item.title }}
              </div>
              <div class="hvl-v-i-bt">
                <div class="hvl-v-i-n">
                  <van-icon name="play-circle-o"/>
                  <span>{{ item.playBackCounts }}次</span></div>
                <span class="hvl-v-i-t">{{ item.modifyTime.substr(0, 10) }}</span>
              </div>
            </div>
          </template>
          <div class="hvl-container-empty" v-if="videoList.length==0">
            <img src="../../assets/help/blank_common.png" alt="">
            <span>暂无相关视频!</span>
          </div>
        </div></div>
      </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";

interface HelpVideoListState {
  categoryId: string,
  sortConditionShow: boolean,
  isLoading: boolean,
  conditions: { name: string, value: string }[],
  conditionIndex: number,
  videoList: {
    title?: string,
    modifyTime?: string,
    content?: string,
    playBackCounts?: string,
    coverUrl?: string,
    videoUrl?: string,
  }[]
  categories: {
    id: "",
    name: ''
  }[]
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const cState = reactive<HelpVideoListState>({
          videoList: [],
          isLoading: true,
          categories: [],
          sortConditionShow: false,
          conditionIndex: 0,
          categoryId: '',
          conditions: [{
            name: '默认排序',
            value: '1'
          }, {
            name: '播放量',
            value: '2'
          }, {
            name: '发布时间',
            value: '3'
          }]
        })
        onBeforeMount(() => {
          getVideoCategories()
        })
        onMounted(() => {

        })
        const getVideoList = async (keywordId) => {
          Toast.loading({
            message: '查询中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getTopicsByKeyWordId({
            keywordId
          })
          Toast.clear()
          cState.isLoading = false
          if (res.success) {

          }
        }
        const getVideoCategories = async () => {
          let res = await helpApis.getVideoCategories({})
          if (res.success) {
            cState.categories = res.result
            cState.categoryId = res.result[0] ? res.result[0].id : ''
            getVideoByCategoryId()
          }
        }
        const getVideoByCategoryId = async () => {
          Toast.loading({
            message: '',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getVideoByCategoryId({
            categoryId: cState.categoryId,
            orderType: cState.conditions[cState.conditionIndex].value
          })
          Toast.clear()
          if (res.success) {
            cState.videoList = res.result.map(item => {
              item.coverUrl = process.env.VUE_APP_BACKEND_URL + '/' + item.coverUrl
              item.videoUrl = process.env.VUE_APP_BACKEND_URL + '/' + item.videoUrl
              return item
            })
          }
        }
        const goBack = () => {
          router.go(-1)
        }
        const goVideoDetail = (id) => {
          router.push({
            path: "/help/video/detail/" + id
          })
        }
        const changeConditionIndex = (index) => {
          cState.conditionIndex = index
          cState.sortConditionShow = false
          getVideoByCategoryId()
        }
        const categoryChanged = (index)=>{
          cState.conditionIndex = 0
          cState.categoryId = cState.categories[index]['id']
          getVideoByCategoryId()
        }
        return {
          ...toRefs(cState),
          goBack,
          goVideoDetail,
          changeConditionIndex,
          categoryChanged
        }
      }
    }
)
</script>

<style lang="scss">
.hvl-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
  z-index: 9;
  .van-tabs__nav--line {
    padding-bottom: 0px;
  }

  .van-tabs__line {
    width: 50px;
    bottom: 0px;
  }

  .van-tabs--line .van-tabs__wrap {
    height: 42px;
  }

  .van-tab--active {
    .van-tab__text {
      font-size: 15px;
      color: #F84E00;
    }
  }

  .van-tab__text {
    font-size: 15px;
    color: #303030;
  }

  .hvl-bottom {
    flex: 1;
    position: relative;
    z-index: 14;
    overflow-y: auto;
    .hvl-bottom-mask {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 12;
      background-color: rgba(0, 0, 0, 0.35);
    }
    .hvl-itemlist{
      height: 100%;
      overflow-y: scroll;
    }
    .hvl-v-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      padding: 6px 15px;
      width: 100%;
      .hvl-container-empty{
        height: 350px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          height: 120px;
          width: 140px;
        }
        span{
          margin-top: 10px;
          font-size: 14px;
          color: #737373;
        }
      }
      .hvl-v-item {
        width: 48%;
        margin-bottom: 14px;
        .hvl-v-i-cover {
          height: 100px;
          img {
            height: 100%;
            width: 100%;
            border-radius: 4px;
          }
        }

        .hvl-v-i-title {
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-top: 10px;
        }

        .hvl-v-i-bt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .hvl-v-i-n {
            display: flex;
            align-items: center;

            color: #999999;

            i {
              margin-right: 4px;
              top: 1px;
              position: relative;
              font-size: 16px;
            }

            span {
              font-size: 12px;
            }
          }

          .hvl-v-i-t {
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }

  .hvl-search-c {
    position: relative;

    .hvl-search-tab {
      position: relative;
    }

    .hvl-search-line {
      height: 2px;
      background-color: #f3f3f3;
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .hvl-sort-c {
      position: relative;
      z-index: 15;
      .hvl-sort-t {
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 42px;

        .hvl-sort-select {
          font-size: 12px;
          margin-left: 4px;
        }
      }

      .hvl-sort-conditions {
        position: absolute;
        width: 100%;
        left: 0;
        top: 42px;
        z-index: 3;
        background-color: white;

        .hvl-sort-condition {
          height: 44px;
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px;
          border-top: 1px solid #e5e5e5;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .hvl-sort-selected {
            color: #f84e00;
          }

          &.active {

          }
        }
      }
    }

  }

}

</style>
