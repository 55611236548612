import http from '../../utils/http'
export default{
    getSaleSetting(params: any): Promise<any>{
        return http.get(`/api/customer/payment/subscriptionPackages`,params)
    },
    payForSaleSettingWx(params: any): Promise<any>{
        return http.get(`/api/customer/payment/paymentPub`,params)
    },
    payForSaleSettingH5(params: any): Promise<any>{
        return http.get(`/api/customer/payment/paymentH5`,params)
    },
    getOrderStatus(params: any): Promise<any>{
        return http.get(`/api/customer/payment/myOrder`,params)
    },
    getUserPhoneWithRecommendCode(params: any): Promise<any>{
        return http.get(`/api/customer/commission/PhoneNumberByRecommendCode`,params)
    },
    findReferralByCode(params: any): Promise<any>{
    return http.post(`/api/customer/recommendCode/checkRecommendCode`,params)
    },
    doExchangeCode(params: any): Promise<any>{
        return http.post(`/api/customer/promotion/exchange`,params)
    },
    getExchangeCodeInfo(params: any): Promise<any>{
        return http.get(`/api/customer/promotion/promotionCodeInfo`,params)
    },
    coupon(params: any): Promise<any>{
        return http.get(`/api/customer/coupon/couponCode`,params)
    },

}
