
import {defineComponent, inject, onBeforeMount, onBeforeUnmount, ref} from "@vue/runtime-core";
import {useRoute, useRouter} from 'vue-router';
import {toRefs,reactive} from "@vue/reactivity";
import {Toast,Form} from "vant"
import helper from "@/utils/helper";
import loginApis from '../api/login/index'
import  _ from "lodash"
import Utils from "@/utils/helper";
import payApis from "@/api/pay";
interface captchRes{
  captchaBaBase64String?:string,
  captchaId?: string
}
export default defineComponent({
  name: "Login",
  setup(){
    const router = useRouter();
    const route = useRoute()
    const getUserData = inject('getUserData')
    const userData = inject('userData')
    const loginFormRef = ref<any>(null)
    const formState = reactive({
      userNameOrEmailAddressOrPhoneNumber:'',
      captcha:"",
      captchaImg:""
    })
    const cState = reactive({
      timerCount:60,
      timerCountShow:false,
      captchaImgUrl:'',
      recommendCode:'',
      recommendpPhone:'',
      isRecommandPay: false,
      errorFormTip:""
    })
    let timer:any = null
    let errorTimer:any = null
    let  captchaImgRes:captchRes = {}
    onBeforeMount(()=>{
      Utils.userLogout()
      // if(route.query.s!==undefined){
      //   let encryptionData = ''
      //   try {
      //     encryptionData = Utils.decryptCode(route.query.s)
      //   }catch (e) {
      //     encryptionData = ''
      //   }
      //   if(!!encryptionData && encryptionData.length == 6){
      //     cState.recommendCode = encryptionData
      //     getUserPhoneWithCode()
      //   }
      // }
      getGetCaptchaImg()
    })
    onBeforeUnmount(()=>{
      clearInterval(timer)
    })
    const getUserPhoneWithCode = async ()=>{
      let res = await payApis.getUserPhoneWithRecommendCode({
        code:cState.recommendCode
      })
      console.log(res)
      if(res.success){
        cState.recommendpPhone = res.result
        cState.isRecommandPay = true
      }else{
        // 获取不到对应的用户信息.无效推荐码
        cState.isRecommandPay = false
      }
    }
    const getGetCaptchaImg = async ()=>{
      let res = await loginApis.getCaptchaImg({})
      console.log(res)
      if(res.success){
         cState.captchaImgUrl = 'data:image/png;base64,'+res.result.captchaBaBase64String
         captchaImgRes = res.result
      }
    }
    const throwLoginError = (error)=>{
      cState.errorFormTip = error
      errorTimer = setTimeout(()=>{
        cState.errorFormTip = ''
      },5000)
    }
    const onSubmit = async (values:any) => {
      if(!/^1[3-9]\d{9}$/.test(formState.userNameOrEmailAddressOrPhoneNumber)){
        throwLoginError('手机号格式错误!')
        return
      }
      if(!/^[0-9]{6}$/.test(formState.captcha)){
        throwLoginError('请输入正确的短信验证码!')
        return
      }
      let res = await loginApis.loginWithPhoneCode({
        userNameOrEmailAddressOrPhoneNumber:formState.userNameOrEmailAddressOrPhoneNumber,
        captcha:formState.captcha
      });
      console.log(res)
      if(res.success){
        Toast.success('登录成功!');
        localStorage.setItem('access_token','Bearer '+res.result.accessToken);
        if(Utils.getBrowserInfo().isWeixin){
          loginApis.bindingWeiXinByLogin({
            openId:userData['openId']
          });
        }

        (getUserData as any)();
        setTimeout(()=>{
          let params:{
            [name:string]:any
          } = {

          }
          if(route.query.s!==undefined){
            params.s = route.query.s
          }
          if(route.query.redirect_url!==undefined){
            router.replace({
              path:decodeURIComponent(route.query.redirect_url as string) ,
              query:params
            })
          }else{
            router.replace({
              path:'/pay',
              query:params
            })
          }
        },1000)
      }else{
        Toast.fail(_.get(res,'result.error.message','登录失败!'));
      }
    };
    const getPhoneCode = async ()=>{
      if(!/^1[3-9]\d{9}$/.test(formState.userNameOrEmailAddressOrPhoneNumber)){
        throwLoginError('手机号格式错误!')
        return
      }
      if(!/^[0-9A-Za-z]{4}$/.test(formState.captchaImg)){
        throwLoginError('请输入4位图形验证码!')
        return
      }
      let res = await loginApis.getAuthGetcaptcha({
        phoneNumber: formState.userNameOrEmailAddressOrPhoneNumber,
        captchaId: captchaImgRes.captchaId,
        captcha: formState.captchaImg,
        captchaType:"1"
      })
      getGetCaptchaImg()
      if(res.success){
        cState.timerCount = 60
        cState.timerCountShow = true
        timer = setInterval(()=>{
          cState.timerCount-=1
          if(cState.timerCount==0){
            cState.timerCountShow = false
            clearInterval(timer)
          }
        },1000)
        Toast.success('发送成功!');
        if(process.env.VUE_APP_MODE !=='prod'){
         setTimeout(()=>{ Toast({
           message: '验证码:'+res.result+'正式环境不会出来',
           duration: 5000
         })},2000)
        }

      }else{
        console.log('1111');
        Toast.fail(_.get(res,'result.error.message','发送失败!'));
      }
    }
    const validatorMessage = async (val:string)=>{
      if(!/^1[3-9]\d{9}$/.test(val)){
        return '请输入合法手机号！'
      }
    }
    return {
      ...toRefs(formState),
      ...toRefs(cState),
      onSubmit,
      getGetCaptchaImg,
      getPhoneCode,
      validatorMessage,
      loginFormRef
    }
  }
})
