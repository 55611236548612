import http from '../../utils/http'
export default{
    getUserInfo(params: any): Promise<any>{
        return http.get(`/api/customer/user/currentUserInfo`,params)
    },
    getWeixinOpenId(params: any): Promise<any>{
        return http.get(`/api/app/wei-xin/open-id`,params)
    },
    recordPv(params: any): Promise<any>{
        return http.get(`/api/customer/commission/increaseViews`,params)
    }
}
