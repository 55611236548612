<template>
  <div class="hr-container">
    <div class="hr-top">
      <div class="search-c">
        <div class="hr-search-close">
          <van-icon name="cross" @click="goBack()" />
        </div>
        <div class="hr-top-left">
          <van-field
              v-model="searchValue"
              label=""
              left-icon="search"
              placeholder="请输入关键词"
              center
          >
          </van-field>
          <div class="hr-top-mask" @click="goToSearchPage()"></div>
        </div>
        <div class="hr-top-right">
          <van-button size="small" type="default" @click="showAllResult()">搜索</van-button>
        </div>
      </div>
    </div>
    <div class="hr-bottom">
      <div class="hr-topic-c" v-if="searchTopicResult.length!=0&&topicStatus&&!isLoading">
        <div class="hr-topic-title">
          为您找到的文档
        </div>
        <div class="hr-topic-items">
          <template v-for="(item,index) of searchTopicResult" :key="index">
            <div class="hr-topic-item" v-if="!(videoStatus&&index>=3)" @click="goToTopicDetail(item.id)">
              {{item.title}}
            </div>
          </template>

        </div>
        <div class="hr-common-btn" v-if="searchTopicResult.length>3" @click="videoStatus=!videoStatus">
          <span>{{videoStatus?'更多相关文档':'全部搜索结果'}}</span>
        </div>
      </div>
      <div class="hr-v-c" v-if="searchVideoResult.length!=0&&videoStatus&&!isLoading">
        <div class="hr-v-title">
          为您找到的视频
        </div>
        <div class="hr-v-items">
          <template v-for="(item,index) of searchVideoResult" :key="index">
            <div class="hr-v-item" v-if="!(topicStatus&&index>=4)" @click="goVideoDetail(item.id)">
              <div class="hr-v-i-cover">
                <img  v-lazy="item.coverUrl" />
              </div>
              <div class="hr-v-i-title">
                {{item.title}}
              </div>
              <div class="hr-v-i-bt">
                <div class="hr-v-i-n"><van-icon name="play-circle-o" /><span>{{item.playBackCounts}}次</span></div>
                <span class="hr-v-i-t">{{item.modifyTime.substr(0,10)}}</span>
              </div>
            </div>
          </template>

        </div>
        <div class="hr-common-btn" v-if="searchVideoResult.length>4" @click="topicStatus=!topicStatus">
          <span>{{topicStatus?'更多相关视频':'全部搜索结果'}}</span>
        </div>
      </div>
      <div class="hr-bottom-empty" v-if="searchVideoResult.length==0&&searchTopicResult.length==0&&!isLoading">
        <img src="../../assets/help/blank_search.png" alt="">
        <span>
          未搜索到相关结果，建议尝试搜索其他关键词
如：“CAD”、“表格编辑”、“单位工程”、“页眉页脚”等。
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";
interface HelpResultState {
  searchValue: string | string[],
  searchTopicResult: {
    title:string,
    id:string
  } [],
  searchVideoResult: {
    playBackCounts:number,
    modifyTime:string,
    coverUrl:string,
    id:string,
    title:string
  } [],
  keywordId: string | string[],
  topicStatus: boolean,
  videoStatus: boolean,
  isLoading: boolean,
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const cState = reactive<HelpResultState>({
          searchValue: '',
          keywordId: '',
          searchTopicResult: [],
          searchVideoResult: [],
          topicStatus:true,
          videoStatus:true,
          isLoading:true
        })
        onBeforeMount(() => {
          let query = router.currentRoute.value.query
          if(query.keywordId){
            cState.keywordId = query.keywordId
          }
          if(query.keyword){
            cState.searchValue = decodeURIComponent(query.keyword as string)
          }
        })
        onMounted(() => {
          if(cState.keywordId){
            getTopicsByKeyWordId(cState.keywordId)
          }else{
            getTopicsByKeyWord(cState.searchValue)
          }
        })
        const getTopicsByKeyWordId = async (keywordId)=>{
          Toast.loading({
            message: '查询中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getTopicsByKeyWordId({
            keywordId
          })
          Toast.clear()
          cState.isLoading = false
          if(res.success){
            cState.searchTopicResult = res.result.imageAndTexts
            cState.searchVideoResult = res.result.videos.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return  item
            })
          }
        }
        const getTopicsByKeyWord = async (keyword)=>{
          Toast.loading({
            message: '查询中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          let res = await helpApis.getTopicsByKeyWord({
            keyword
          })
          Toast.clear()
          cState.isLoading = false
          if(res.success){
            cState.searchTopicResult = res.result.imageAndTexts
            cState.searchVideoResult = res.result.videos.map(item=>{
              item.coverUrl = process.env.VUE_APP_BACKEND_URL+'/'+item.coverUrl
              return  item
            })
          }
        }
        const goBack = _.throttle(()=>{
          router.go(-1)
        },1000)
        const goToSearchPage= ()=>{
          router.replace({
            path:'/help/search',
            query:{
              keyword: encodeURIComponent(cState.searchValue as string)
            }
          })
        }
        const goToTopicDetail = (id)=>{
          router.push({
            path:"/help/topic/detail/"+id
          })
        }
        const goVideoDetail = (id)=>{
          router.push({
            path:"/help/video/detail/"+id
          })
        }
        const showAllResult = ()=>{
          cState.topicStatus = true
          cState.videoStatus = true
        }
        return {
          ...toRefs(cState),
          goBack,
          goToSearchPage,
          goToTopicDetail,
          goVideoDetail,
          showAllResult
        }
      }
    }
)
</script>

<style scoped lang="scss">
.hr-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .hr-top {
    height: 58px;
    width: 100%;
    padding: 12px;
    background-color: #f84e00;
    display: flex;
    align-items: center;

    .search-c {
      height: 28px;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .hr-search-close{
      width: 40px;
      color: white;
    }
    .hr-top-left {
      flex: 1;
      position: relative;
      height: 100%;
      .hr-top-mask{
        width: 100%;
        position: absolute;
        left: 0;
        top:0;
        height: 100%;
        background-color: rgba(0,0,0,0)
      }
      .van-cell {
        height: 100%;
        padding: 0px 12px;
        border-radius: 4px;
      }
    }

    .hr-top-right {
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .van-button {
        background-color: #f84e00;
        height: 100%;
        border: none;
        color: white;
        font-size: 14px;
      }
    }
  }

  .hr-bottom {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    background-color: #e5e5e5;
    .hr-common-btn{
      text-align: center;
      span{
        display: inline-block;
        background-color: #eeeeee;
        border-radius: 4px;
        border: solid 1px #cccccc;
        text-align: center;
        padding: 4px 8px;
        font-size: 14px;
        color: #999999;
      }
    }
    .hr-topic-c{
      background-color: white;
      padding: 15px;
      margin-bottom: 15px;
      .hr-topic-title{
        font-size: 14px;
        color: #999999;
        margin-bottom: 10px;
      }
      .hr-topic-item{
        margin-bottom: 8px;
        font-size: 16px;
        @include z-t-ellipsis;
        color: #333333;
      }
    }
    .hr-bottom-empty{
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      align-items: center;
      height: 100%;
      img{
        width: 204px;
        height: 152px;
      }
      span{
        font-size: 15px;
        margin-top: 20px;
        font-family: "Noto Sans SC";
        color: #999999;
        display: block;
        width: 308px;
        height: 92px;
      }
    }
    .hr-v-c{
      background-color: white;
      padding: 15px;
      .hr-v-title{
        font-size: 14px;
        color: #999999;
        margin-bottom: 15px;
      }
      .hr-v-items{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .hr-v-item{
          width: 48%;
          margin-bottom: 14px;
          .hr-v-i-cover{
            height: 100px;
            img{
              height: 100%;
              width: 100%;
              border-radius: 4px;
            }
          }
          .hr-v-i-title{
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin-top: 10px;
          }
          .hr-v-i-bt{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            .hr-v-i-n{
              display: flex;
              align-items: center;

              color: #999999;
              i{
                margin-right: 4px;
                top:1px;
                position: relative;
                font-size: 16px;
              }
              span{
                font-size: 12px;
              }
            }
            .hr-v-i-t{
              font-size: 12px;
              color: #999999;
            }
          }
        }
      }
    }
  }
}

</style>
