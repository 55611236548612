<template>
  <router-view></router-view>
  <div class="help-feedback-c" @click="goToFeedBack()" v-if="showBtn">
    <img src="../assets/help/icon_feedback.png" alt="">
    <span>留言板</span>
  </div>
</template>

<script>
import {defineComponent, onBeforeMount} from "@vue/runtime-core";
import {useRouter,onBeforeRouteUpdate} from "vue-router";
import {ref} from "@vue/reactivity";
import * as _ from "lodash";
import {provide} from "vue";
export default defineComponent(
    {
      name: "Help",
      setup(){
        const showBtn  = ref(true)
        const router = useRouter();
        onBeforeMount(()=>{
          //window.location.href = 'https://cb32.com'
          //
          if(router.currentRoute.value.path == '/help/feedback'){
            showBtn.value = false
          }
        })
        onBeforeRouteUpdate((params)=>{
          if(params.path == '/help/feedback'){
            showBtn.value = false
          }else{
            showBtn.value = true
          }
        })
        const goToFeedBack = ()=>{
          router.push({
            path:"/help/feedback"
          })
        }
        const getSearchList = () => {
          let searchListStr = localStorage.getItem('search_list')
          let searchList = []
          if(searchListStr) {
            try{
              searchList = JSON.parse(searchListStr)
            }catch (e){
              searchList = []
            }
          }
          searchList = searchList.filter(item=>{
            return _.trim(item)!==''
          })
          return searchList
        }
        const addTextToSearchList = (text) =>{
          let searchList = getSearchList()
          let findIndex = searchList.findIndex(item=>item==text)
          if(findIndex!=-1){
            searchList.splice(findIndex,1)
          }
          if(searchList.length>=5){
            searchList = searchList.splice(0,4)
          }
          searchList.unshift(text)
          localStorage.setItem('search_list',JSON.stringify(searchList))
          return searchList
        }
        // 关键词跳转
        const goToSearchResult = (item)=>{
          addTextToSearchList(item.text)
          router.push({
            path:'/help/result',
            query:{
              keyword: encodeURIComponent(item.text),
              keywordId:item.id
            }
          })
        }
        provide('goToSearchResult',goToSearchResult)
        provide('getSearchList',getSearchList)
        provide('addTextToSearchList',addTextToSearchList)
        return {
          goToFeedBack,
          showBtn
        }
      }
    }
)
</script>

<style scoped lang="scss">
  .help-feedback-c{
    position: fixed;
    bottom:15px;
    right: 15px;
    height: 56px;
    width: 56px;
    background-image: linear-gradient(320deg,
        #f84e00 0%,
        #ffa556 100%);
    box-shadow: 1px 4px 7px 0px
    rgba(51, 51, 51, 0.4);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 28px;
    z-index:10;
    img{
      height: 18px;
      width: 18px;
    }
    span{
      font-size: 10px;
    }
  }
</style>
