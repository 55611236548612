
import {defineComponent, inject, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import {Toast} from "vant"
import * as _ from 'lodash'

interface HelpSearchState {
  searchValue: string,
  searchList:string[],
  keywordList:{
    id:string,
    text:string
  }[]
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const searchInput = ref(null)
        const addTextToSearchList = inject('addTextToSearchList')
        const getSearchList = inject('getSearchList')
        const cState = reactive<HelpSearchState>({
          searchValue: '',
          searchList:[],
          keywordList:[]
        })
        onBeforeMount(() => {
          //window.location.href = 'https://cb32.com'
          //
          let query = router.currentRoute.value.query
          if(query.keyword){
            cState.searchValue = decodeURIComponent(query.keyword as string)
          }
        })
        onMounted(() => {
          getTopKeywordList()
          cState.searchList = (getSearchList as any)()
          searchInput.value.focus()
        })
        const getTopKeywordList = async ()=>{
          let res = await helpApis.getTopKeywordList({})
          if(res.success){
            cState.keywordList = res.result
          }
        }
        const goBack = _.throttle(()=>{
          router.go(-1)
        },1000)
        const clearSearchList = ()=>{
          localStorage.removeItem('search_list')
          cState.searchList = []
        }
        const searchTopic = (text,id)=>{
          if(!text){
            text = cState.searchValue
          }

          let extraParams = {}
          if(id){
            extraParams['keywordId'] = id
          }
          if(!text) {
            Toast.fail('请输入关键词!')
          }else{
            cState.searchList = (addTextToSearchList as any)(text)
            router.replace({
              path:'/help/result',
              query:{
                keyword: encodeURIComponent(text),
                ...extraParams
              }
            })
          }

        }
        return {
          ...toRefs(cState),
          searchInput,
          goBack,
          clearSearchList,
          searchTopic
        }
      }
    }
)
