<template>
 <div></div>
</template>

<script lang="ts">
import {defineComponent} from "@vue/runtime-core";
import {toRefs} from "@vue/reactivity";
export default defineComponent({
  name: "Home",
  setup(){
    const state = {

    }
    return toRefs(state)
  }
})
</script>

<style scoped lang = "scss">

</style>
