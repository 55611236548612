import md5 from 'js-md5';
export default {
    handleResponse:(res:any)=>{
        if(res.success == undefined){
            return {
                success: true,
                result:res.data
            }
        }else{
            return res
        }
    },
    getBrowserInfo: function () {
        var u = navigator.userAgent, app = navigator.appVersion;
        console.log(u)
        console.log(u.toLowerCase().indexOf('micromessenger') != -1)
        return {         //移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            isWeixin:u.toLowerCase().indexOf('micromessenger') != -1
        };
    },
    decryptCode:function (encryptstr){
        let arr = []
        for(let i=0;i<encryptstr.length;i++){
            if(i%2 == 1){

            }else{
                arr.push(encryptstr.charAt(i))
            }
        }
        arr.reverse()
        return arr.join('')
    },

    encryptCode:function (code){
        let codeArr = code.split('')
        codeArr.reverse()
        // 简单写个算法混淆code,ascii码表翻转
        let str = ''
        let arr = md5(code).toString().toUpperCase().substr(0,code.length).split('')
        for(let i=0;i<codeArr.length;i++){
            str += codeArr[i]
            str += arr[i]
        }
        return str
    },
    isLogin:function ():boolean{
        if(localStorage.getItem('access_token')){
            return true
        }else{
            return false
        }
    },
    userLogout:function (){
       localStorage.removeItem('access_token')
    },
    downloadBlob(name: string, blob: any) {
        //const objectUrl = (window.URL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        const aTag = document.createElement('a');
        document.body.appendChild(aTag);
        aTag.setAttribute('style', 'display:none');
        aTag.setAttribute('href', blob);
        aTag.setAttribute('download', name);
        aTag.click();
        document.body.removeChild(aTag);
    },
    navToWebUrl(url:string){
        if(!this.getBrowserInfo().mobile){
            window.location.href = process.env.VUE_APP_WEB_URL+url+window.location.search
        }
    }
}
