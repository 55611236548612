
import {defineComponent, inject, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter,onBeforeRouteUpdate} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import * as _ from 'lodash'
import qs from "qs";
import {Toast} from "vant";
interface HelpTopicState {
  topicInfo:{
    title?:string,
    modifyTime?:string,
    content?:string
  },
  topicKeywords:{
    id?:string,
    text?:string
  }[],
  topicRels:{
    title?:string,
    modifyTime?:string,
    content?:string
  }[],
}

export default defineComponent(
    {
      name: "HelpTopic",
      setup() {
        const router = useRouter();
        const addTextToSearchList = inject('addTextToSearchList')
        const goToSearchResult = inject('goToSearchResult')
        const cState = reactive<HelpTopicState>({
          topicInfo:{

          },
          topicKeywords:[],
          topicRels:[]
        })
        onBeforeMount(() => {
          let query = router.currentRoute.value.params

          if(!query.id){
           Toast.fail('图文不存在!')
          }else {
            getTopicsInfoById(query.id)
            getTopicsKeyWord(query.id)
            getTopicsRelation(query.id)
          }
        })
        onMounted(() => {

        })
        onBeforeRouteUpdate((to) => {
          getTopicsInfoById(to.params.id)
          getTopicsKeyWord(to.params.id)
          getTopicsRelation(to.params.id)
        });
        const getTopicsInfoById = async (id)=>{
          let res = await helpApis.getTopicsInfoById({
            id
          })
          if(res.success){
             cState.topicInfo = res.result
          }else{
            Toast.fail('图文不存在!')
          }
        }
        const getTopicsKeyWord= async (id)=>{
          let res = await helpApis.getTopicsKeyWord({
            id
          })
          if(res.success){
            cState.topicKeywords = res.result
          }
        }
        const getTopicsRelation= async (id)=>{
          let res = await helpApis.getTopicsRelation({
            id
          })
          if(res.success){
            cState.topicRels = res.result
          }
        }
        const goToOtherTopicPage= (id)=>{
          router.push({
            path:'/help/topic/detail/'+id
          })
        }
        const goBack = ()=>{
          router.go(-1)
        }
        return {
          ...toRefs(cState),
          goBack,
          goToOtherTopicPage,
          goToSearchResult
        }
      }
    }
)
