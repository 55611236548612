<template>
  <div class="pay-container">
    <div class="p-user-corner-b">

    </div>
    <div class="p-user-corner-c">
      <div class="p-user-corner">

      </div>
    </div>
    <div class="user-c">
      <div class="user-i">
        <div class="user-avatar"><img src="../assets/pay/user.png" alt=""></div>
        <div class="user-phonevip">
          <div class="user-phone">
            <span>{{ userInfo.phoneNumber }}</span><img v-if="userInfo.isVip" class="user-vip-img"
                                                        src="../assets/pay/vip.png" alt="">
          </div>
          <div class="user-vip-t" v-if="!userInfo.isVip">免费版</div>
          <div class="user-viptime" v-if="userInfo.isVip">
            <span>VIP到期时间</span><span>&nbsp;{{ userInfo.vipDueTime ? userInfo.vipDueTime.substr(0, 10) : '' }}</span>
          </div>
        </div>
      </div>
<!--      <div class="user-recommend" @click="onGoToRecommend()">推荐赚佣金></div>-->
    </div>
    <div class="vip-privilege">
      <div class="vip-title">
        <div class="vip-title-line"></div>
        <div class="vip-title-dot"></div>
        <div class="vip-title-t">开通个人版享特权</div>
        <div class="vip-title-dot"></div>
        <div class="vip-title-line"></div>
      </div>
      <div class="vip-items">
        <div class="vip-item">
          <img src="../assets/pay/p1.png" alt="">
          <div><p>最多10个项目</p>
            <p>单项目2000张表格</p></div>
        </div>
        <div class="vip-item">
          <img src="../assets/pay/p2.png" alt="">
          <div><p>支持表格导出</p>
            <p>支持表格打印</p></div>
        </div>
        <div class="vip-item">
          <img src="../assets/pay/p3.png" alt="">
          <div><p>专家在线答疑</p>
            <p>优先体验高级功能</p></div>
        </div>
      </div>
    </div>
    <div class="pay-c">
      <div class="pay-cc">
        <div class="pay-typec" v-if="!isRecommandPay">
          <div class="pay-type" :class="{active:isPay}" @click="isPay=!isPay"><span>购买</span></div>
          <div class="pay-type" :class="{active:!isPay}" @click="isPay=!isPay"><span>兑换</span></div>
        </div>
         <div class="pay-type1" v-if="isPay">
           <div class="pay-extra" v-if="isRecommandPay">
             <div class="pay-extra-p1">您的好友<span style="font-weight: 600">{{ recommendPayInfo.phone }}</span>邀请您购买建龙云资料
             </div>
             <div class="pay-extra-p2">限时优惠，即刻购买可多送一个月会员</div>
           </div>
           <div class="pay-p1">
             <div class="pay-p1-t">选择方案</div>
             <div class="pay-p1-c">
               <div class="pay-p1-i" :class="{checked:saleSetting===item.id}" v-for="(item,index) in saleSettings"
                    :key="index" @click="saleSettingChanged(item)">
                 <div class="pay-p1i-t">
                   {{ item.name }}
                 </div>
                 <div class="pay-p1i-num">
                   <span class="pay-p1i-num1">￥{{ item.realPrice }}</span> <span
                     class="pay-p1i-num2">￥{{ item.virtualPrice }}</span>
                 </div>
                 <div class="pay-p1i-desc">
                <span class="pay-p1i-di"
                      v-if="item.virtualPrice - item.realPrice!==0">立省{{ item.virtualPrice - item.realPrice }}元</span>
                 </div>
               </div>
             </div>
           </div>
           <div class="pay-p2">
             <div class="pay-p2-t">选择地区</div>
             <div class="pay-p2-c">
               <div class="pay-p2-i" :class="{checked:area===item}" v-for="(item,index) in areaList" :key="index"
                    @click="areaChanged(item)">{{ item }}
               </div>
             </div>
           </div>
<!--           <div class="pay-p3">-->
<!--             <div class="pay-p3-t">优惠码</div>-->
<!--             <div class="pay-p3-c">-->
<!--               <van-field-->
<!--                   v-model="couponCode"-->
<!--                   center-->
<!--                   clearable-->
<!--                   label="填入优惠码:"-->
<!--                   placeholder="请输入优惠码"-->
<!--               >-->
<!--                 <template #button>-->
<!--                   <van-button size="small" type="primary" class="pay-p3-btn" @click="onCheckCcode()">兑换</van-button>-->
<!--                 </template>-->
<!--               </van-field>-->
<!--               <div class="pay-p3-tips">{{ couponMsg }}</div>-->
<!--             </div>-->
<!--           </div>-->
           <div class="pay-p3" v-if="!isRecommandPay">
             <div class="pay-p3-t">推荐人</div>
             <div class="pay-p3-c">
               <van-field
                   v-model="recommendCode"
                   center
                   clearable
                   maxlength="6"
                   label="填入推荐码:"
                   placeholder="请输入推荐码"
               >
                 <template #button>
                   <van-button :disabled="recommendCode.length!=6" size="small" type="primary" class="pay-p3-btn"
                               @click="onCheckRcode()">使用
                   </van-button>
                 </template>
               </van-field>
               <div class="pay-p3-tips">{{ inviteMsg }}</div>
             </div>
           </div>
           <div class="pay-p6">
             <div class="pay-p6-t">支付方式</div>
             <div class="pay-p6-c">
               <div class="pay-p6-i">
                 <div class="pay-p6-ii"><img src="../assets/pay/weixin.png" alt="">微信支付</div>
                 <div class="pay-p6-ic">
                   <img src="../assets/pay/select.png" alt="">
                 </div>
               </div>
             </div>
           </div>
           <div class="pay-p4" v-if="payPrice!=''">
             <div class="pay-account">
               <span class="pay-a-p1">实付金额:</span>
               <span class="pay-a-p1t">￥{{ tradePrice }}</span>
               <span class="pay-a-p2">合计:</span>
               <span class="pay-a-p2t">￥{{ payPrice }}</span>
               <span class="pay-a-p3">优惠:</span>
               <span class="pay-a-p3t">￥{{ couponPrice }}</span>
             </div>
             <div class="pay-vip-time">付费后会员到期时间:{{ vipNewTime }}</div>
           </div>
           <div class="pay-p5">
             <van-checkbox-group v-model="agreement" icon-size="14px">
               <van-checkbox shape="square" name="1" checked-color="#f15504"></van-checkbox>
             </van-checkbox-group>
             <div class="pay-agreement"><span @click="onAgreementChanged()">我已阅读并同意</span><span @click="onShowAgreement()">《使用条例》</span>
             </div>
           </div>
           <van-button :loading="isPayLoading" loading-text="正在生成订单..." block type="primary" class="pay-btn"
                       @click="onWeixinPay()">
             立即开通
           </van-button>
         </div>
         <div class="pay-type2" v-if="!isPay">
           <van-field
               v-model="exchangeCode"
               center
               clearable
               maxlength="16"
               label="填入兑换码:"
               placeholder="请输入兑换码"
           >
           </van-field>
           <div class="exchange-code-item" v-if="newExchangeCodeInfo.attr">
             <div class="exchange-code-title">
               兑换属性:
             </div>
             <div class="exchange-code-content">
               {{newExchangeCodeInfo.attr}}
             </div>

           </div>
           <div class="exchange-code-item" v-if="newExchangeCodeInfo.attr">
             <div class="exchange-code-title">
               兑换到期日:
             </div>
             <div class="exchange-code-content">
               {{newExchangeCodeInfo.endVipTimeStr}}
             </div>

           </div>
           <div class="desc">
             <div class="desc-tilte">使用说明</div>
             <div class="desc-content">
               <p>1.兑换码仅限已登录用户使用,兑换后立即获得相应权益;</p>
               <p>2.兑换码分为一年、两年和三年的会员功能;</p>
               <p>3.请在兑换有效期内使用,过期兑换码自动作废;</p>
               <p>4.兑换码在使用过程中一次性生效;</p>
               <p>5.兑换码不可兑换现金。请注意兑换码保密性,若发生盗用、泄露。遗失等问题不予调换与退款。</p>
             </div>
           </div>
           <van-button :loading="isPayLoading" loading-text="正在兑换中..." block type="primary" class="pay-btn"
                       @click="onDoExchangeCode()">
             立即兑换
           </van-button>
         </div>
      </div>
    </div>
    <div class="pay-ar" v-if="agreementModal">
      <div class="pay-ar-mask" @click="onCloseAgreement(false)"></div>
      <div class="pay-ar-c">
        <div class="pay-ar-text">
          <div class="bulb-editor bulb-editor-inner"
               style="white-space: pre-wrap; overflow-wrap: break-word; user-select: unset; cursor: text;">
            <div
                style="text-align: center;"><span
                class="main-title">使用条款</span>
            </div>
            <div
                style="text-align: center;"><span
                class="custom-font font-family">最后更新：2020&nbsp;年 3&nbsp;月 10&nbsp;日</span>
            </div>
            <div
                style="text-align: center;"><span class=""
                                                  style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >欢迎您成为本网站注册用户，并申请及接受四川省建科院建龙软件（以下简称“建龙”）提供的服务。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >在您注册成为本网站用户前，敬请务必仔细阅读本协议所有条款，特别是限制、免除建龙责任和限制用户权利的条款。若您一旦注册为本网站用户，或使用&nbsp;cb32.com，spdqd.com 网站或者任何与建龙，建龙软件、SPD产品相关的服务，则意味着您已完全接受本协议项下的全部条款及本网站公布的各项业务规则。若您不同意本协议条款，敬请立即停止注册本网站用户或主动停止使用本服务。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙保留在不提前通知用户的情况下，对本协议条款或业务规则做任何修改或补充的权利，修改或补充后的协议条款或业务规则将在本网站公布，并自公布时立即生效。未来发布的任何新的基于本服务的增强功能，包括新的工具和资源都将自动适用本协议，您继续访问本网站或继续使用新服务即视为您接受修订后的本协议及业务规则；否则，您应通过停止访问且拒绝使用服务、删除您在本网站的帐户等方式终止本协议。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >如果您代表某个机构而非您个人注册、登录和使用本网站和/或服务，则您将被认为获得充分授权代表该机构同意本协议条款以及本协议条款不时的修改和补充。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >A. 帐户条款</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您必须具有完全民事行为能力和民事权利能力方可使用本服务。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >为了能正常访问本网站和使用本服务，您必须依照本网站注册提示填写准确的、真实的姓名/名称、电子邮箱、手机号以及其他任何在注册时要求的信息，并须确保在上述注册信息发生变化时及时更新信息及确保更新信息的有效性和合法性。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >若您提供任何错误、虚假、过时或不完整的资料，或者建龙依其独立判断怀疑资料为错误、虚假、过时或不完整，建龙有权限制、封禁您在本网站的帐号，及暂停或终止提供服务的部分或全部功能。在此情况下，建龙将不向您承担任何责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您的帐户只可以由您本人登录及使用，多人共享帐号是被禁止的。不过您可以根据购买的套餐内容，在本协议及相关业务规则允许的范围内，添加多个协作帐户。您知悉并同意，您与协作帐户使用人之间或各协作帐户使用人之间的任何纠纷将由您及/或相关方自行解决及承担责任，与建龙无关；建龙将不会介入该等纠纷的处理，亦不会就此向您或任何相关方承担任何责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您有责任维护您的帐户和密码的安全性。建龙不会因您未能遵守这一安全保障义务而对因此导致的任何损失或损害承担任何责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您仅能为自身内部运营之目的使用本服务，您不得出于任何非法或未经授权的目的使用本服务，包括但不限于不得以营利或非营利目的以任何方式向任何第三方提供本服务或其任何部分，不得利用本服务从事任何违法或侵犯第三方知识产权或其他合法权益的活动。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您在使用本服务过程中应遵守所有可适用的法律法规及本协议、业务规则的规定，不得发生任何违法、违反本协议或侵犯第三方合法权益的行为；您应对您帐户项下的全部行为承担相应法律责任。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >B. 支付，退款，升级/降级 条款</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >所有的支付必须通过一个有效的支付宝/微信完成。本服务收费方式为预付费、按月/年扣费。非因建龙原因导致本服务在服务期限内中止或终止的，已支付费用不予退还。建龙不会为未使用完的月费、年费、升级/降级套餐退款，也不会为服务计划中尚未使用的剩余期限退款。为了保证公平地对待每一个用户，不存在例外情况。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >所有费用均为不含税价格，您应负责支付及自行承担所有及任何税金。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >服务降级后可能会导致您帐户项下的内容、功能、特征或能力的丧失。您明确知悉并同意上述变化及风险，建龙对因此发生的任何损失不承担任何责任。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >C. 解除和终止</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您只能以电子邮件提出注销帐户申请。您须按照上述规定注销帐户，并应对注销帐户的行为负责。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >一旦您的帐户被注销，所有在本服务中的内容都将被立即删除，该等内容及任何信息将无法恢复。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >如果您在缴足费的服务期届满</span><span
                class="custom-font"
                style="font-size: 18px; padding-top: 1px; padding-bottom: 1px;">&#8203;&#8203;</span><span
                class="custom-font font-family"
            >之前取消服务，您的取消将立即生效，您将不会被收取后续费用，剩余服务期的已缴付费用不予退还。</span>
            </div>
            <div
                style="text-indent: 28px;"><span class="custom-font font-family"
            >如您违反本协议任何条款、任何业务规则或可适用的法律法规，或发生任何第三方侵权投诉，建龙将有权限制、封禁您在本网站的帐号，及暂停或终止提供服务的部分或全部功能，并有权要求您赔偿因此给建龙造成的全部损失。除此之外，建龙将有权因任何原因，在任何时间自行决定暂停或终止您的帐号，或拒绝提供任何/所有现在或将来的服务。如果因建龙原因终止向您提供某项收费服务，建龙的义务仅在于向您返还您尚未使用的服务期对应的部分费用。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >终止本服务将导致终止及删除您的帐户及帐户中的所有内容及信息。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙保留无解释的，对任何人因任何原因在任何时间拒绝服务的权利。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >D. 服务和价格的修订</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙保留随时变更、中止或终止部分或全部服务的权利，并保留根据实际情况随时调整建龙提供的服务种类、形式、费用标准的权利。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >所有服务的价格，包括但不限于包月/包年计划收费服务的改变，都会收到来自建龙的通知。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙可通过推送通知、邮件、讨论区公告、全站公告或改变建龙网站或服务本身等方式对服务的变更、终止或终止及服务价格及其变更进行通知。您继续访问本网站及/或使用本服务，即视为您接受变更后的服务及价格。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >因服务变更、中止或终止，或服务价格变更对您与第三方之间的任何法律关系产生任何影响的，建龙将不承担任何责任。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >E. 用户内容版权及合法性规则</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙不拥有您在本服务中使用的您自身的任何素材的版权。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您知悉并同意，您将项目设置为公开，即表示您同意让其他人查看您的内容（包括同意公开任何尚未发表的著作权内容），并同意及允许他人浏览和复制您的项目及其任何要素、内容。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您应确保使用本服务上传、存储、传播的任何内容的合法性，确保内容不违反任何法律法规的规定，不违反公序良俗，版权清洁且您有权通过本服务上传、存储、传播，不侵犯任何第三方的知识产权或其他合法权益。任何与您上传、存储、传播的内容有关的知识产权纠纷及其他纠纷将由您与相关方自行解决并承担全部责任，与建龙无关，建龙因此遭受任何损失的，您同意全额赔偿。本平台如果收到关于内容合法性或权属的任何投诉或异议，将有权不经通知用户删除相关内容。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙不预先筛选内容；如任何第三方对您的任何内容提出任何异议，或建龙认为您的任何内容涉嫌违法、违反本协议、侵犯第三方权益、存在任何不当，或可能影响其他用户或第三方权益，建龙有自行决定拒绝或删除任何内容的权利（而非义务），且不承担任何责任。您必须为第三方因认为您的内容违法、</span><span
                class="custom-font"
                style="font-size: 18px; padding-top: 1px; padding-bottom: 1px;">&#8203;&#8203;</span><span
                class="custom-font font-family"
            >违反本协议，侵犯第三方的权益（包括知识产权）或与您有关的其他原因而对建龙提出的任何索赔、主张、请求或诉讼，赔偿建龙因此发生的全部费用及遭受的全部损失。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙有权，但没有义务，删除在建龙看来包含非法的、攻击、威胁、中伤、诽谤、淫秽、色情或任何其他不良信息的内容和帐户，或删除任何违反法律规定、本协议约定、对建龙任何用户、员工或第三方造成侵害或损害，或建龙认为可能存在任何其他不良影响的内容和帐户。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >你不得传播任何蠕虫或病毒或具有破坏</span><span
                class="custom-font"
                style="font-size: 18px; padding-top: 1px; padding-bottom: 1px;">&#8203;&#8203;</span><span
                class="custom-font font-family"
            >性的任何代码。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >本服务允许用户上传、存储内容并允许其他用户浏览、复制或以其他方式使用用户设置为公开的内容。您了解并知悉，该等用户上传、存储的内容，内容所有权、知识产权归属用户所有，与内容相关的一切责任亦由用户自行承担，与建龙无关。您通过本服务使用其他用户或第三方提供的内容时，应标明出处及内容提供方，商业性或营利性使用应事先取得内容提供方的许可或同意；您未能遵守上述使用规则所引发的责任及第三方损失将由您自行承担，与建龙无关，给建龙造成损失的，还须赔偿建龙的全部损失。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >F. 建龙版权</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >本网站及本服务的外观设计、应用程序、源代码、商标、标示图案（LOGO）、界面设计、应用程序编程接口及相关著作权，以及与建龙提供的服务有关的任何著作权及其他知识产权均归建龙所有，其它本协议中未经提及的权利亦由建龙保留。未经建龙事先书面许可，您不能复制、拷贝、模仿或者使用上述知识产权的任何部分，不得复制、拷贝或使用本服务的任何部分HTML/ CSS，JavaScript或视觉设计元素和理念。未经建龙事先书面同意，您不得为任何目的自行实施、利用、转让或许可他人实施上述任何知识产权或其任何部分。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >G. 隐私条款</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您同意本网站及建龙为提供本服务及优化服务等相关目的收集、保留、存储您的用户信息及一些用户选项（例如隐私设置）。您同意建龙收集和使用有关您所使用服务的技术性或诊断性信息。收集到的这些信息将用于改进本网站或本协议服务的内容和技术，提升服务品质。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙将不会在不通知的情况下透露用户的个人信息，除非根据法律法规的规定、国家行政、司法机关的要求予以披露，或在建龙为保护建龙、建龙其他用户或任何第三方的合法权益认为必要时进行披露。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >其他隐私政策请见本网站不时公布及修订的隐私条款。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >H. 有限保证及服务免责</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您同意建龙只基于“现况”及“现有”基础提供服务。建龙保证提供的本服务与相应的服务介绍及建龙不时公布的服务承诺相符。除上述保证外，建龙不对本网站及/或本服务作出任何其他明示或暗示的保证，包括但不限于不保证：<br>（1）本服务将满足您的具体要求或需要；（2）本服务将不间断，及时、安全或无差错；（3）使用本服务所获得的结果准确或可靠；（4）本服务的任何错误都将被纠正。您知悉及同意上述服务状况，并同意自行承担使用本服务的全部风险。</span>
            </div>
            <div
                style="text-indent: 28px;"><span
                class="custom-font font-family"
            >您知悉及同意，建龙不对您可能通过建龙接触的到任何内容负责，您同意自担风险使用本服务。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >基于互联网的开放性属性，您知悉将图片、信息或任何资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，您必须充分意识到此类风险的存在；您明确同意使用本网站及本协议服务所存在的风险将完全由您自己承担；因第三方侵权所产生的责任和损失将由您向第三方追索，建龙无须就此向您承担任何责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙只向正在付费中的帐户持有人提供技术支持。支持仅提供中/英文版本。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您知悉并理解本服务使用第三方供应商或托管合作伙伴提供必要的硬件、软件、网络、存储以及相关的技术来运行服务，您同意遵守该等第三方的任何业务规则，并同意因该等第三方硬件、软件、网络、存储以及相关技术所引发的任何故障、意外、责任或风险将由您与该等第三方解决，建龙不就此作出任何承诺或保证，亦不承担任何责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您不得以任何方式明示或暗示您或非建龙所有及运营的任何网站或服务与建龙有任何关联。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >不论在何种情况下，建龙均不对由于Internet连接故障，通讯或其他系统的故障，黑客攻击、病毒、电力故障，罢工，劳动争议，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令，第三方的不作为或任何建龙不能合理控制的原因而造成的本平台不能访问、信息及数据的延误、停滞或错误，不能提供或延迟提供本服务而承担责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙将采取现有技术水平项下合理可行的措施努力保护用户帐号和数据隐私，但建龙不能确保用户的全部私人通讯及其他资料不会通过本隐私规则中未列明的途径泄露出去，您知悉并同意承担上述风险，并同意建龙无须就此承担任何责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >不论是否可以预见，不论是源于何种形式的行为或不作为，建龙不对因任何原因造成的任何特别的、间接的、惩罚性的、突发性的或其他任何损害（包括但不限于利润或利息的损失、因您隐私泄漏、您个人资料等的泄露、被盗用等，因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他原因造成的金钱上的损失或其他损失等）承担责任。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >除建龙故意损害用户利益以外，任何情况下，建龙可能向您承担的赔偿、补偿或其他任何责任的限额将不超过您就该等服务向建龙支付的服务费金额。在赔偿损失时设定了限额，是因为建龙在订立本协议时无法预见到您的损失；若您的损失可能大于赔偿限额的，您应当在签订本协议前书面告知建龙，并根据风险大小与建龙另行协商确定服务价格。</span>
            </div>
            <div
                style=""><span class=""
                               style="padding-top: 1px; padding-bottom: 1px;"><span>&nbsp;</span></span>
            </div>
            <div
                style=""><span
                class="custom-title"
            >I. 其他规定</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >您同意未经建龙的明确书面许可，不复制、出售、转售或以其他方式向第三方提供本服务或其任何部分。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >如果您的带宽使用率显著高于平均带宽，或建龙认为您占用其他建龙客户的资源，建龙将保留立即禁用帐户的权利或终止您项目的托管，直到您减少您的带宽消耗至建龙认为合理的水平。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >本协议项下建龙向您发出的所有通知，建龙可按以下任一方式发出：（1）通过推送通知、邮件、讨论区公告、全站公告等形式发出；（2）向您注册时或者此后变更您信息时向建龙提供的电子邮箱发送电子邮件（发出即视为送达）。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >建龙未能或延迟行使本协议规定的任何权利、权力或特权不应视为放弃行使，且任何单一或部分权利、权力或特权的行使不应排除权利、权力或特权的任何其他或进一步的行使或本协议项下任何其他权利、权力或特权的行使。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >本协议构成您与建龙之间的全部协议，并取代您与建龙之间的任何先前的协议（包括但不限于本协议的任何以前的版本）。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >本协议之解释与适用，均应依照中华人民共和国法律进行。与本协议有关的争议，由建龙所在地有管辖权的法院管辖。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >任何与本服务条款有关的问题请致电400-060-3788咨询。</span>
            </div>
            <div
                style="text-indent: 28px; "><span
                class="custom-font font-family"
            >本协议最终解释权归建龙所有。</span>
            </div>

          </div>
        </div>
        <div class="pay-ar-action">
          <van-button block type="primary" class="pay-ar-btn" @click="onCloseAgreement(true)">
            我已阅读并同意
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  watch,
  watchEffect
} from "@vue/runtime-core";
import {reactive, toRefs} from "@vue/reactivity";
import payApis from '../api/pay/index'
import _ from "lodash"
import moment from 'moment'
import {CustomModule} from '@/interface'
import {Toast} from "vant";
import Utils from '@/utils/helper'
import {useRoute, useRouter} from "vue-router";
import qs from 'qs'
import userApi from '../api/user'
import {Dialog} from 'vant';

declare let WeixinJSBridge: any

interface SalesSetting {
  area: string,
  id: string,
  creationTime?: string,
  name?: string,
  realPrice?: string,
  timeSpan?: string,
  virtualPrice?: string
}

interface SaleState {
  saleSettings: SalesSetting[],
  saleSetting: string,
  areaList: string[],
  area?: string,
  payPrice?: string,
  tradePrice?: string,
  vipNewTime?: string,
  orderId?: string,
  recommendCode?: string,
  recommendCodeUser?: boolean,
  isRecommandPay?: boolean,
  newExchangeCodeInfo?:{
    attr?: string,
    endVipTimeStr?: string
  }
  recommendPayInfo?: {
    code?: string,
    phone?: string
  },
  couponCode?: string,
  couponPrice?: string,
  couponMsg?: string,
  inviteStatus?: string,
  inviteMsg?: string,
  agreement?: any[],
  agreementModal?: boolean,
  isPayLoading?: boolean,
  isPay?:boolean,
  exchangeCode?:string
}

interface PaySchema {
  //支付类型,Alipay = 0,Wxpay = 1
  PayModeType: number;
  //订阅套餐 id
  SubscriptionPackageId: string;
  //优惠券
  CouponCode?: string;
  //token
  Token?: string;
  RecommendCode?: string;
  OpenId?: string;
}

enum PayStatus {
  'PENDING' = 1,
  'SUCEESS' = '2',
  'CLOSE' = '3',
  'REFUND' = '4'
}

export interface WeiXinRes { // 微信需要传入的数据，数据格式定义
  appId?: string;
  timeStamp?: string;
  nonceStr?: string;
  package?: string;
  signType?: string;
  sign?: string;
}

export default defineComponent({
  name: "Pay",
  setup() {
    const router = useRouter();
    const route = useRoute()
    // tradePrice = payPrice - couponPrice
    const cState = reactive<SaleState>({
      saleSettings: [],
      saleSetting: '',
      areaList: [],
      area: '',
      payPrice: '',
      tradePrice: "",
      vipNewTime: '',
      orderId: '',
      recommendCode: '',
      recommendCodeUser: false,
      couponCode: '',
      couponPrice: '0',
      couponMsg: '',
      isRecommandPay: false,
      recommendPayInfo: {
        code: '',
        phone: ''
      },
      newExchangeCodeInfo:{
        attr:"",
        endVipTimeStr:""
      },
      inviteStatus: '',
      inviteMsg: '',
      agreement: [],
      agreementModal: false,
      isPayLoading: false,
      isPay:true,
      exchangeCode:''
    })
    const userInfo: CustomModule.UserInfo = inject('userData', {})
    const getUserData:any = inject('getUserData')
    let weixinRes: WeiXinRes = {}
    let saleMap = {}
    let saleAllSettings = []
    let orderStatusTimer = null
    let orderStatusInterval = null
    let isLoadingOrderStatus = false
    watch(() => cState.recommendCode, (newValue, oldValue) => {
      cState.inviteMsg = ''
      cState.inviteStatus = ''
      cState.recommendCodeUser = false
      if (cState.saleSetting) {
        // 选择了套餐
        let findIndex = saleAllSettings.findIndex((item) => {
          return item.id == cState.saleSetting
        })
        console.log(findIndex)
        if (findIndex != -1) {
          vipTimeCompute(saleAllSettings[findIndex]['timeSpan'])
        }
      }
    })
    watch(() => cState.couponCode, (newValue, oldValue) => {
      cState.couponPrice = ''
      let tp = isNaN(parseFloat(cState.payPrice)) ? 0 : parseFloat(cState.payPrice)
      let dp = isNaN(parseFloat(cState.couponPrice)) ? 0 : parseFloat(cState.couponPrice)
      cState.tradePrice = (tp - dp).toString()
    })
    watch(()=>cState.exchangeCode,async (newValue, oldValue)=>{
      if (cState.exchangeCode.length == 16) {
        let res = await payApis.getExchangeCodeInfo({ Code: newValue });
        console.log(res)
        if (res.success && !!res.result.promotionBatchId && res.result.status == 1) {
          let vipDueTime = userInfo.vipDueTime;
          let mytime = moment(res.result.expiredTime);
          if (mytime.isBefore(moment())) {
            Toast.fail('无效兑换码!');
            return
          }
          let endVipTimeStr = '';
          let vipCodeDay = res.result.timeSpan.split('.')[0];
          let localvipDueTime = moment(vipDueTime);
          if (!!vipDueTime && localvipDueTime.isAfter(moment())) {
            // 会员兑换
            endVipTimeStr = localvipDueTime.add(vipCodeDay, 'd').format('YYYY年MM月DD日');

          } else {
            // 非会员兑换
            endVipTimeStr = moment().add(vipCodeDay, 'd').format('YYYY年MM月DD日');

          }
          let attr = '';
          if (vipCodeDay >= 365) {
            if (vipCodeDay % 365 == 0) {
              attr = Math.floor(vipCodeDay / 365) + '年';
            } else {
              attr = Math.floor(vipCodeDay / 365) + '年' + Math.floor(vipCodeDay % 365 / 31) + '个月';
            }

          } else if (vipCodeDay >= 31) {
            attr = Math.floor(vipCodeDay / 31) + '个月';
          } else {
            attr = vipCodeDay + '天';
          }
          cState.newExchangeCodeInfo = {
            attr,
            endVipTimeStr
          };
        } else {
          Toast.fail('无效兑换码!');
        }

      } else {
        cState.newExchangeCodeInfo = {};
      }
    })
    watchEffect(() => {
      if (userInfo.recommendCode == cState.recommendCode) {
        if(cState.isRecommandPay){
          cState.isRecommandPay = false
          cState.recommendCode = ''
        }

      }
    })
    onBeforeMount(() => {
      if (Utils.isLogin()) {
        // if (route.query.s !== undefined) {
        //   let encryptionData = ''
        //   try {
        //     encryptionData = Utils.decryptCode(route.query.s)
        //   } catch (e) {
        //     encryptionData = ''
        //   }
        //   if (!!encryptionData && encryptionData.length == 6) {
        //     if (userInfo && userInfo.recommendCode == encryptionData) {
        //       cState.isRecommandPay = false
        //       cState.recommendCode = ''
        //     } else {
        //       cState.isRecommandPay = true
        //       cState.recommendCode = encryptionData
        //       cState.recommendPayInfo.code = encryptionData
        //       getUserPhoneWithCode()
        //     }
        //   }
        // }
        fetchSalesSetting()
      }
    })
    onMounted(() => {
      if(route.query.type&&route.query.type=='2'){
        cState.isPay = false
      }
      if (!Utils.isLogin()) {
        Dialog.alert({
          title: '登录提示',
          message: '您还没有登录,请先登录后再进行支付购买',
        }).then(() => {
          // on close
          if (route.query.s !== undefined) {
            let params = {
              redirect_url: encodeURIComponent('/pay'),
              s: route.query.s
            }
            router.push('/login?' + qs.stringify(params))
          } else {
            let params = {
              redirect_url: encodeURIComponent('/pay')
            }
            router.push('/login?' + qs.stringify(params))
          }
        });
      }

    })
    onBeforeUnmount(() => {
      clearTimeout(orderStatusTimer)
      clearInterval(orderStatusInterval)
    })
    const getUserPhoneWithCode = async () => {
      let res = await payApis.getUserPhoneWithRecommendCode({
        code: cState.recommendCode
      })
      if (res.success) {
        cState.isRecommandPay = true
        userApi.recordPv({code: cState.recommendCode})
        cState.recommendPayInfo.phone = res.result
      } else {
        // 获取不到对应的用户信息.无效推荐码
        cState.isRecommandPay = false
      }
    }
    const fetchSalesSetting = async () => {
      let res = await payApis.getSaleSetting({
        IsTest: process.env.VUE_APP_MODE !== 'prod'
      })
      if (res.success) {
        saleMap = {}
        let lists: SalesSetting[] = res.result
        saleAllSettings = res.result.concat([])
        const address: (string | undefined)[] = [];
        lists.map((item: SalesSetting) => {
          address.push(item.area)
          if (saleMap[item.area] === undefined) {
            saleMap[item.area] = [];
          }
          saleMap[item.area].push(item);
        })
        cState.areaList = _.uniq(address)

        cState.area = cState.areaList[0]
        cState.saleSettings = saleMap[cState.area]
        saleSettingChanged(saleMap[cState.area][0])
      }
    }
    const areaChanged = (item) => {
      cState.area = item
      // 切换套餐
      cState.saleSettings = saleMap[cState.area]
      saleSettingChanged(cState.saleSettings[0])
    }
    const vipTimeCompute = (timeSpan: string) => {
      const day: string = timeSpan.split('.')[0];
      const time = timeSpan.split('.')[1];
      const hour: string = time.split(':')[0];
      const minute: string = time.split(':')[1];
      const second: string = time.split(':')[2];
      const nowTimestamp = moment()
      const oldMoment = !userInfo.isVip ? nowTimestamp : moment(userInfo.vipDueTime, "YYYY-MM-DD HH:mm:ss")
      const timestamp = parseInt(day) * 86400000 + parseInt(hour) * 3600000 + parseInt(minute) * 60000 + parseInt(second) * 1000;
      const newMoment = oldMoment.add(timestamp, 'ms')
      if (cState.isRecommandPay || cState.recommendCodeUser) {
        cState.vipNewTime = newMoment.add(1, 'month').format('YYYY年MM月DD日')
      } else {
        cState.vipNewTime = newMoment.format('YYYY年MM月DD日')
      }

      //const timestamp = day * 86400000 + hour * 3600000 + minute * 60000 + second * 1000;
    }
    const saleSettingChanged = (item: SalesSetting) => {
      cState.saleSetting = item.id
      // 切换价格
      cState.payPrice = item.realPrice
      let tp = isNaN(parseFloat(cState.payPrice)) ? 0 : parseFloat(cState.payPrice)
      let dp = isNaN(parseFloat(cState.couponPrice)) ? 0 : parseFloat(cState.couponPrice)
      cState.tradePrice = (tp - dp).toString()
      // 切换到期时间
      vipTimeCompute(item.timeSpan)
    }
    const getOrderStatus = async () => {
      clearInterval(orderStatusInterval)
      orderStatusInterval = setInterval(async () => {
        if (isLoadingOrderStatus) {
          return
        }
        isLoadingOrderStatus = true
        let res = await payApis.getOrderStatus({
          OrderId: cState.orderId
        })
        isLoadingOrderStatus = false
        if (res.success) {
          if (res.result.status == PayStatus.SUCEESS) {
            (getUserData as any)();
            clearInterval(orderStatusInterval)
            Toast.success('支付成功!')
          } else if (res.result.status == PayStatus.CLOSE && res.result.status == PayStatus.REFUND) {
            clearInterval(orderStatusInterval)
            Toast.success('支付失败!')
          }
        }
      }, 3 * 1000)
      clearTimeout(orderStatusTimer)
      orderStatusTimer = setTimeout(() => {
        clearInterval(orderStatusInterval)
      }, 30 * 60 * 1000)
    }
    const onWeixinPay = async () => {
      if (!cState.saleSetting) {
        Toast.fail('请选择套餐!');
        return
      }
      if (cState.agreement.length == 0) {
        Toast.fail('请同意使用条例!');
        return
      }
      const schema: PaySchema = {
        OpenId: userInfo.openId,
        PayModeType: 1,
        RecommendCode: cState.recommendCode,
        SubscriptionPackageId: cState.saleSetting,
        CouponCode: cState.couponCode,
        Token: localStorage.getItem('access_token').replace('Bearer ', '')
      }
      if (cState.isPayLoading) {
        return
      }
      cState.isPayLoading = true
      if (Utils.getBrowserInfo().mobile) {//判断是否是移动设备打开。browser代码在下面
        var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
        if (Utils.getBrowserInfo().isWeixin) {
          //在微信中打开
          let res = await payApis.payForSaleSettingWx(schema)
          cState.isPayLoading = false
          if (res.success) {
            cState.orderId = res.result.orderId
            getOrderStatus()
            weixinRes = {
              appId: res.result.parameter.appId,
              timeStamp: res.result.parameter.timeStamp,
              nonceStr: res.result.parameter.nonceStr,
              package: res.result.parameter.package,
              signType: res.result.parameter.signType,
              sign: res.result.parameter.paySign
            }
            weChartJSBridge()
          } else {
            Toast.fail('生成订单失败!')
          }
        } else {
          let res = await payApis.payForSaleSettingH5(schema)
          cState.isPayLoading = false
          if (res.success) {
            cState.orderId = res.result.orderId
            const url = new URL(window.location.href);
            let redirec_uri = encodeURIComponent(url.origin + '/paystatus?orderId=' + cState.orderId)
            window.location.href = res.result.mWebUrl + '&redirect_url=' + redirec_uri
          } else {
            Toast.fail('生成订单失败!')
          }
          //api/customer/payment/paymentH5
          //非微信浏览器打开
        }
      }
    }
    const onCheckRcode = async () => {
      if (cState.recommendCode == userInfo.recommendCode) {
        cState.inviteStatus = 'error';
        cState.inviteMsg = '无法使用本人的推荐码!';
        cState.recommendCodeUser = false
        return
      }
      let res = await payApis.findReferralByCode({
        code: cState.recommendCode
      })
      if (res.success) {
        if (res.result.userId) {
          cState.recommendCodeUser = true
        }
        cState.inviteStatus = 'success';
        cState.inviteMsg = '推荐人：' + res.result.name;
        if (cState.saleSetting) {
          // 选择了套餐
          let findIndex = saleAllSettings.findIndex((item) => {
            return item.id == cState.saleSetting
          })
          console.log(findIndex)
          if (findIndex != -1) {
            vipTimeCompute(saleAllSettings[findIndex]['timeSpan'])
          }
        }
      } else {
        cState.inviteStatus = 'error';
        cState.inviteMsg = '﹡' + res.result.error.message;
      }
    }
    const onCheckCcode = async () => {
      if (!cState.saleSetting) {
        Toast.fail('请选择至少一个套餐!')
        return
      }
      let res = await payApis.coupon({
        Code: cState.couponCode,
        Price: cState.payPrice
      })
      if (res.success) {
        cState.couponPrice = res.result.deductionAmount
        let tp = isNaN(parseFloat(cState.payPrice)) ? 0 : parseFloat(cState.payPrice)
        let dp = isNaN(parseFloat(cState.couponPrice)) ? 0 : parseFloat(cState.couponPrice)
        cState.tradePrice = (tp - dp).toString()
      } else {
        cState.tradePrice = cState.payPrice
        cState.couponMsg = '﹡' + res.result.error.message;
      }
    }
    const onShowAgreement = () => {
      cState.agreementModal = true
    }
    const onCloseAgreement = (v) => {
      if (v) {
        cState.agreementModal = false
        cState.agreement = ['1']
      } else {
        cState.agreementModal = false
      }

    }
    const onGoToRecommend = () => {
      router.push('/r')
    }
    const weChartJSBridge = () => {
      if (typeof WeixinJSBridge === 'undefined') { // WeixinJSBridge 在TS编译会报错，因为该对象只在微信浏览器中存在，在文件头部声明 declare let WeixinJSBridge: any 即可
        document.addEventListener('WeixinJSBridgeReady', () => {
          onBridgeReady(weixinRes)
        }, false)
      } else {
        onBridgeReady(weixinRes)
      }
    }
    const onBridgeReady = (res: WeiXinRes) => {
      console.log(res)
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
            appId: res.appId,
            // 公众号名称，由商户传入
            timeStamp: res.timeStamp,
            // 时间戳，自1970年以来的秒数
            nonceStr: res.nonceStr,
            // 随机串
            package: res.package,
            signType: res.signType,
            // 微信签名方式：
            paySign: res.sign
            // 微信签名
          },
          (res: any) => {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              Toast.success('支付成功!')
              router.push({
                path: '/paystatus',
                query: {
                  orderId: cState.orderId
                }
              })
            }
            if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              // 支付取消
              Toast.fail('支付取消!')
            }

            if (res.err_msg === 'get_brand_wcpay_request:fail') {
              // 支付失败
              Toast.fail('支付失败!')
            }
          })
    }
    const onAgreementChanged = () => {
      if (cState.agreement.length != 0) {
        cState.agreement = []
      } else {
        cState.agreement = ['1']
      }
    }
    const onDoExchangeCode = async ()=>{
      let res = await payApis.doExchangeCode({
        code:cState.exchangeCode
      })
      if(res.success){
        Toast.success('恭喜您,VIP兑换成功!')
        getUserData()
      }else{
        Toast.fail('兑换码失败!')
      }
    }
    return {
      ...toRefs(cState),
      userInfo,
      areaChanged,
      saleSettingChanged,
      onWeixinPay,
      onGoToRecommend,
      onCheckRcode,
      onCheckCcode,
      onCloseAgreement,
      onShowAgreement,
      onAgreementChanged,
      onDoExchangeCode
    }
  }
})
</script>

<style lang="scss">
$user-bg-color: #252527;
.pay-container {
  height: 100%;
  width: 100%;
  background-color: #F8F8F8;
  position: relative;

  .user-c {
    position: absolute;
    top: 0;
    padding: 30px 30px 0px 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;

    .user-i {
      width: 100%;
      display: flex;
      color: white;
      align-items: center;

      .user-phonevip {
        margin-left: 8px;

        .user-phone {
          font-size: 15px;
          font-weight: 500;
          display: flex;
          align-items: center;

          .user-vip-img {
            width: 20px;
            height: 20px;
            margin-left: 6px;
            position: relative;
            top: 2px;
          }
        }

        .user-viptime {
          margin-top: 2px;
          font-size: 12px;
          font-weight: 300;

          span {
            margin: 0;
          }
        }

        .user-vip-t {
          display: inline-block;
          font-size: 12px;
          font-weight: 300;
          border-radius: 10px;
          height: 18px;
          width: 50px;
          text-align: center;
          line-height: 18px;
          transform: scale(0.8);
          box-sizing: content-box;
          border: 1px solid #ffffff;
        }
      }

      .user-avatar {
        width: 54px;
        height: 54px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .user-recommend {
      color: #e9bc23;
      font-size: 13px;
      width: 120px;
      position: relative;
      padding-top: 4px;
      font-weight: 0;
      text-align: right;
    }
  }

  .vip-privilege {
    width: 360px;
    height: 180px;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #ffffff;
    box-shadow: 0px 3px 22px 0px rgba(22, 16, 14, 0.15);
    border-radius: 16px;
    padding: 20px 10px;

    .vip-title {
      display: flex;
      align-items: center;
      justify-content: center;

      .vip-title-line {
        height: 2px;
        width: 70px;
        background-color: #808080;
      }

      .vip-title-dot {
        height: 2px;
        width: 2px;
        background-color: #a4a4a4;
        margin: 0 6px;
      }

      .vip-title-t {
        color: #656565;
        width: 120px;
        height: 14px;
        line-height: 14px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .vip-items {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;

      .vip-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 60px;
          height: 60px;
        }

        div {
          font-size: 12px;
          margin-top: 10px;
          color: #666666;
          line-height: 12px;
          width: 100px;

          p {
            margin-bottom: 2px;
            margin-top: 0;
            text-align: center;
          }
        }
      }
    }
  }

  .p-user-corner-c {
    width: 100%;
    overflow: hidden;
    height: 200px;
    position: relative;
    top: -10px;

    .p-user-corner {
      width: 130%;
      height: 200px;
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: $user-bg-color;
      transform: translate(-50%, -50%);
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }

  .pay-c {
    width: 360px;
    position: absolute;
    top: 310px;
    left: 50%;
    transform: translate(-50%, 0);
    padding-bottom: 30px;
  }

  .pay-cc {
    height: auto;
    background-color: #ffffff;
    box-shadow: 0px 3px 22px 0px rgba(22, 16, 14, 0.15);
    border-radius: 16px;
    padding: 0px;
    .pay-type1{
      padding: 0px 10px 40px 10px;
    }
    .pay-type2{
      padding: 20px 10px 40px 10px;
      .van-cell{
        padding: 0;
        margin-bottom: 20px;
      }
      .van-field__label{
        font-size: 16px;
      }
      .van-field__control{
        font-size: 16px;
      }
      .van-cell::after{
        display: none;
      }
      .exchange-code-item{
        margin-top: 4px;
        display: flex;
        align-items: center;
        .exchange-code-title{
          font-size: 14px;
          color: #616161;
        }
        .exchange-code-content{
          font-size: 14px;
          color: #616161;
          margin-left: 4px;
        }
      }
      .desc{
        margin-top: 20px;
        margin-bottom: 20px;
        .desc-tilte{
          font-weight: bold;
          font-size: 18px;
        }
        .desc-content{
          font-size: 14px;
          margin-top: 10px;
          p{
            margin: 0;
            margin-bottom: 2px;
            color: #666666;
          }
        }
      }
    }
    .pay-extra {
      .pay-extra-p1 {
        color: #4a4a4a;
        font-size: 14px;
      }

      .pay-extra-p2 {
        color: #E1BF80;
        font-size: 12px;
      }
    }
    .pay-typec{
      display: flex;
      width: 100%;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      .pay-type{
       flex: 1;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #545454;
        span{
          padding: 5px 14px;
        }
        &.active{
          span{
            border-bottom: 2px solid #f0743a;
          }
        }
        &:first-child{
          border-top-left-radius: 16px;
        }
        &:last-child{
          border-top-right-radius: 16px;
        }

      }
    }
    .pay-p1 {
      padding-top: 12px;

      .pay-p1-t {
        font-size: 16px;
        color: #363636;
        font-weight: 400;
        margin-bottom: 12px;

      }

      .pay-p1-c {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .pay-p1-i {
          width: 108px;
          height: 140px;
          border-radius: 10px;
          margin-bottom: 14px;
          padding: 12px 0px;
          background-color: #ffffff;
          border: 1px solid #ebebeb;

          &.checked {
            background-image: linear-gradient(to left top, #ef5506 0%, #f3b28f 100%);

            .pay-p1i-t {
              color: #f1f1f1;
            }

            .pay-p1i-num {
              .pay-p1i-num1 {
                color: #ffffff;
              }

              .pay-p1i-num2 {
                color: #d5d5d5;
              }
            }

            .pay-p1i-desc {
              .pay-p1i-di {
                color: white;
                background-color: rgba(239, 107, 41, 0.6);
              }
            }
          }

          .pay-p1i-t {
            font-size: 16px;
            color: #1f1f1f;
            font-weight: 400;
            margin-bottom: 0px;
            width: 100%;
            text-align: center;
          }

          .pay-p1i-num {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            width: 100%;

            .pay-p1i-num1 {
              font-size: 24px;
              font-weight: 500;
              color: #f84e00;
              margin-right: 10px;
              text-align: center;
            }

            .pay-p1i-num2 {
              font-size: 13px;
              color: #c6c6c6;
              text-align: center;
              text-decoration: line-through;
            }
          }

          .pay-p1i-desc {
            display: flex;
            justify-content: center;

            .pay-p1i-di {
              background-color: #fff2e8;
              color: #fc7f56;
              font-size: 12px;
              width: 76px;
              font-weight: 400;
              padding: 3px;
              height: 16px;
              line-height: 16px;
              border-radius: 11px;
              box-sizing: content-box;
              text-align: center;
              @include z-t-ellipsis;
            }
          }

        }
      }
    }

    .pay-p2 {
      padding-top: 12px;

      .pay-p2-t {
        font-size: 16px;
        color: #363636;
        margin-bottom: 12px;
        font-weight: 400;
      }

      .pay-p2-c {
        display: flex;
        flex-wrap: wrap;

        .pay-p2-i {
          padding: 0 18px;
          height: 32px;
          line-height: 32px;
          background-color: #ffffff;
          border-radius: 6px;
          border: solid 1px #edecec;
          margin-bottom: 14px;
          text-align: center;
          @include z-t-ellipsis;
          font-size: 13px;
          font-weight: 300;
          color: #333333;
          margin-right: 10px;

          &.checked {
            background-color: #e76828;
            color: #f5f4f4;
            border: none;
          }
        }
      }
    }

    .pay-p3 {
      padding-top: 12px;

      input {
        font-size: 14px;
      }

      input::-webkit-input-placeholder {
        /* placeholder字体大小 */
        font-size: 14px;
      }

      .pay-p3-t {
        font-size: 16px;
        color: #363636;
        font-weight: 400;
        margin-bottom: 12px;

      }

      .pay-p3-c {
        width: 300px;

        .van-cell {
          padding-left: 0;
          padding-bottom: 0;
        }

        .van-cell::after {
          display: none;
        }

        .pay-p3-tips {
          color: #f9585f;
          font-size: 12px;
        }

        .pay-p3-btn {
          width: 50px;
          height: 26px;
          font-size: 12px;
          position: relative;
          border: none !important;
          background-color: #f5621e !important;
          top: -2px;
        }
      }
    }

    .pay-p6 {
      padding-top: 12px;
      margin-bottom: 30px;

      .pay-p6-t {
        font-size: 16px;
        color: #363636;
        font-weight: 400;
        margin-bottom: 12px;

      }

      .pay-p6-c {
        .pay-p6-i {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .pay-p6-ii {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }

          .pay-p6-ic {
            width: 18px;
            height: 18px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .pay-p4 {
      margin-top: 40px;

      .pay-vip-time {
        font-size: 12px;
        color: #363636;
        margin-top: 4px;
      }

      .pay-account {
        display: flex;
        align-items: flex-end;

        span {
          display: inline-block;
        }

        .pay-a-p1 {
          font-size: 14px;
          line-height: 1;
        }

        .pay-a-p2 {
          font-size: 14px;
          margin-left: 16px;
          line-height: 1;
        }

        .pay-a-p3 {
          font-size: 14px;
          margin-left: 16px;
          line-height: 1;
        }

        .pay-a-p1t {
          font-size: 20px;
          color: #f15504;
          margin-left: 4px;
          line-height: 1;
        }

        .pay-a-p2t {
          font-size: 14px;
          color: #544d4a;
          margin-left: 4px;
          line-height: 1;
        }

        .pay-a-p3t {
          font-size: 14px;
          color: #544d4a;
          text-decoration: line-through;
          margin-left: 4px;
          line-height: 1;
        }
      }
    }

    .pay-p5 {
      display: flex;
      align-items: center;
      margin-top: 5px;

      .van-checkbox-group {
        position: relative;
        top: 1px;
      }

      .pay-agreement {
        font-size: 14px;
        margin-left: 8px;
        color: #545454;
        display: flex;
        align-items: center;

        span:last-child {
          color: #f15504;
        }
      }
    }

    .pay-btn {
      background-image: linear-gradient(-84deg, #f15504 0%, #f39868 100%);
      border: none !important;
      border-radius: 20px;
      height: 40px;
      line-height: 40px;
      margin-top: 12px;
    }
  }

  .pay-ar {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;

    .pay-ar-mask {
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
    }

    .pay-ar-c {
      width: 300px;
      height: 70%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      display: flex;
      flex-direction: column;

      .pay-ar-text {
        flex: 1;
        overflow: scroll;
      }

      .pay-ar-action {
        position: absolute;
        bottom: -50px;
        width: 100%;
        display: flex;
        justify-content: center;

        .pay-ar-btn {
          width: 60%;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
        }
      }
    }

    .bulb-editor {
      padding: 20px 10px;
    }

    .custom-font {
      font-size: 14px;
      color: #4e4e4e;
      font-family: "Noto Sans SC";
      line-height: 14px;
    }

    .main-title {
      padding-top: 1px;
      padding-bottom: 1px;
      font-weight: bold;
      font-size: 24px;
    }

    .custom-title {
      font-size: 16px;
      color: rgb(234, 98, 22);
      font-family: "Noto Sans SC";
      margin-bottom: 50px;
    }
  }
}
</style>
