
import {defineComponent, inject,onBeforeMount, onMounted} from "@vue/runtime-core";
import {useRoute} from "vue-router";
import payApis from '../api/pay/index'
import {reactive, toRefs} from "@vue/reactivity";
import {Toast} from "vant";
enum PayStatus {
  'PENDING'=1,
  'SUCEESS' = '2',
  'CLOSE' = '3',
  'REFUND' = '4'
}
export default defineComponent({
  name: "PayStatus",
  setup(){
    const route = useRoute()
    const cState = reactive({
          isLoading: false,
          status:'',
          orderId: ''
        })
    const getUserData = inject('getUserData')
    let orderStatusTimer = null
    let orderStatusInterval = null
    let isLoadingOrderStatus = false
    const fetchOrderStatus = async ()=>{
      if(isLoadingOrderStatus){
        return
      }
      isLoadingOrderStatus = true
      let res = await payApis.getOrderStatus({
        OrderId: cState.orderId
      })
      isLoadingOrderStatus = false
      if(res.success){
        if(res.result.status == PayStatus.SUCEESS){
          (getUserData as any)();
          clearInterval(orderStatusInterval)
          cState.status= 'success'
        }else if(res.result.status == PayStatus.CLOSE && res.result.status == PayStatus.REFUND){
          clearInterval(orderStatusInterval)
          cState.status= 'fail'
        }
      }
    }
    const getOrderStatus = ()=>{
      clearInterval(orderStatusInterval)
      orderStatusInterval = setInterval( ()=>{
        fetchOrderStatus()
      },2*1000)
      clearTimeout(orderStatusTimer)
      orderStatusTimer = setTimeout(()=>{
        clearInterval(orderStatusInterval)
      },30*60*1000)
    }
    onBeforeMount(()=>{
      console.log(route.query)
      if(route.query.orderId){
         cState.orderId = route.query.orderId as string
        fetchOrderStatus()
        getOrderStatus()
      }
    })
    return {
      ...toRefs(cState)
    }
  }
})
