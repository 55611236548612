
import {
  defineComponent,
  inject,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  watch,
  watchEffect
} from "@vue/runtime-core";
import {reactive, toRefs} from "@vue/reactivity";
import payApis from '../api/pay/index'
import _ from "lodash"
import moment from 'moment'
import {CustomModule} from '@/interface'
import {Toast} from "vant";
import Utils from '@/utils/helper'
import {useRoute, useRouter} from "vue-router";
import qs from 'qs'
import userApi from '../api/user'
import {Dialog} from 'vant';

declare let WeixinJSBridge: any

interface SalesSetting {
  area: string,
  id: string,
  creationTime?: string,
  name?: string,
  realPrice?: string,
  timeSpan?: string,
  virtualPrice?: string
}

interface SaleState {
  saleSettings: SalesSetting[],
  saleSetting: string,
  areaList: string[],
  area?: string,
  payPrice?: string,
  tradePrice?: string,
  vipNewTime?: string,
  orderId?: string,
  recommendCode?: string,
  recommendCodeUser?: boolean,
  isRecommandPay?: boolean,
  newExchangeCodeInfo?:{
    attr?: string,
    endVipTimeStr?: string
  }
  recommendPayInfo?: {
    code?: string,
    phone?: string
  },
  couponCode?: string,
  couponPrice?: string,
  couponMsg?: string,
  inviteStatus?: string,
  inviteMsg?: string,
  agreement?: any[],
  agreementModal?: boolean,
  isPayLoading?: boolean,
  isPay?:boolean,
  exchangeCode?:string
}

interface PaySchema {
  //支付类型,Alipay = 0,Wxpay = 1
  PayModeType: number;
  //订阅套餐 id
  SubscriptionPackageId: string;
  //优惠券
  CouponCode?: string;
  //token
  Token?: string;
  RecommendCode?: string;
  OpenId?: string;
}

enum PayStatus {
  'PENDING' = 1,
  'SUCEESS' = '2',
  'CLOSE' = '3',
  'REFUND' = '4'
}

export interface WeiXinRes { // 微信需要传入的数据，数据格式定义
  appId?: string;
  timeStamp?: string;
  nonceStr?: string;
  package?: string;
  signType?: string;
  sign?: string;
}

export default defineComponent({
  name: "Pay",
  setup() {
    const router = useRouter();
    const route = useRoute()
    // tradePrice = payPrice - couponPrice
    const cState = reactive<SaleState>({
      saleSettings: [],
      saleSetting: '',
      areaList: [],
      area: '',
      payPrice: '',
      tradePrice: "",
      vipNewTime: '',
      orderId: '',
      recommendCode: '',
      recommendCodeUser: false,
      couponCode: '',
      couponPrice: '0',
      couponMsg: '',
      isRecommandPay: false,
      recommendPayInfo: {
        code: '',
        phone: ''
      },
      newExchangeCodeInfo:{
        attr:"",
        endVipTimeStr:""
      },
      inviteStatus: '',
      inviteMsg: '',
      agreement: [],
      agreementModal: false,
      isPayLoading: false,
      isPay:true,
      exchangeCode:''
    })
    const userInfo: CustomModule.UserInfo = inject('userData', {})
    const getUserData:any = inject('getUserData')
    let weixinRes: WeiXinRes = {}
    let saleMap = {}
    let saleAllSettings = []
    let orderStatusTimer = null
    let orderStatusInterval = null
    let isLoadingOrderStatus = false
    watch(() => cState.recommendCode, (newValue, oldValue) => {
      cState.inviteMsg = ''
      cState.inviteStatus = ''
      cState.recommendCodeUser = false
      if (cState.saleSetting) {
        // 选择了套餐
        let findIndex = saleAllSettings.findIndex((item) => {
          return item.id == cState.saleSetting
        })
        console.log(findIndex)
        if (findIndex != -1) {
          vipTimeCompute(saleAllSettings[findIndex]['timeSpan'])
        }
      }
    })
    watch(() => cState.couponCode, (newValue, oldValue) => {
      cState.couponPrice = ''
      let tp = isNaN(parseFloat(cState.payPrice)) ? 0 : parseFloat(cState.payPrice)
      let dp = isNaN(parseFloat(cState.couponPrice)) ? 0 : parseFloat(cState.couponPrice)
      cState.tradePrice = (tp - dp).toString()
    })
    watch(()=>cState.exchangeCode,async (newValue, oldValue)=>{
      if (cState.exchangeCode.length == 16) {
        let res = await payApis.getExchangeCodeInfo({ Code: newValue });
        console.log(res)
        if (res.success && !!res.result.promotionBatchId && res.result.status == 1) {
          let vipDueTime = userInfo.vipDueTime;
          let mytime = moment(res.result.expiredTime);
          if (mytime.isBefore(moment())) {
            Toast.fail('无效兑换码!');
            return
          }
          let endVipTimeStr = '';
          let vipCodeDay = res.result.timeSpan.split('.')[0];
          let localvipDueTime = moment(vipDueTime);
          if (!!vipDueTime && localvipDueTime.isAfter(moment())) {
            // 会员兑换
            endVipTimeStr = localvipDueTime.add(vipCodeDay, 'd').format('YYYY年MM月DD日');

          } else {
            // 非会员兑换
            endVipTimeStr = moment().add(vipCodeDay, 'd').format('YYYY年MM月DD日');

          }
          let attr = '';
          if (vipCodeDay >= 365) {
            if (vipCodeDay % 365 == 0) {
              attr = Math.floor(vipCodeDay / 365) + '年';
            } else {
              attr = Math.floor(vipCodeDay / 365) + '年' + Math.floor(vipCodeDay % 365 / 31) + '个月';
            }

          } else if (vipCodeDay >= 31) {
            attr = Math.floor(vipCodeDay / 31) + '个月';
          } else {
            attr = vipCodeDay + '天';
          }
          cState.newExchangeCodeInfo = {
            attr,
            endVipTimeStr
          };
        } else {
          Toast.fail('无效兑换码!');
        }

      } else {
        cState.newExchangeCodeInfo = {};
      }
    })
    watchEffect(() => {
      if (userInfo.recommendCode == cState.recommendCode) {
        if(cState.isRecommandPay){
          cState.isRecommandPay = false
          cState.recommendCode = ''
        }

      }
    })
    onBeforeMount(() => {
      if (Utils.isLogin()) {
        // if (route.query.s !== undefined) {
        //   let encryptionData = ''
        //   try {
        //     encryptionData = Utils.decryptCode(route.query.s)
        //   } catch (e) {
        //     encryptionData = ''
        //   }
        //   if (!!encryptionData && encryptionData.length == 6) {
        //     if (userInfo && userInfo.recommendCode == encryptionData) {
        //       cState.isRecommandPay = false
        //       cState.recommendCode = ''
        //     } else {
        //       cState.isRecommandPay = true
        //       cState.recommendCode = encryptionData
        //       cState.recommendPayInfo.code = encryptionData
        //       getUserPhoneWithCode()
        //     }
        //   }
        // }
        fetchSalesSetting()
      }
    })
    onMounted(() => {
      if(route.query.type&&route.query.type=='2'){
        cState.isPay = false
      }
      if (!Utils.isLogin()) {
        Dialog.alert({
          title: '登录提示',
          message: '您还没有登录,请先登录后再进行支付购买',
        }).then(() => {
          // on close
          if (route.query.s !== undefined) {
            let params = {
              redirect_url: encodeURIComponent('/pay'),
              s: route.query.s
            }
            router.push('/login?' + qs.stringify(params))
          } else {
            let params = {
              redirect_url: encodeURIComponent('/pay')
            }
            router.push('/login?' + qs.stringify(params))
          }
        });
      }

    })
    onBeforeUnmount(() => {
      clearTimeout(orderStatusTimer)
      clearInterval(orderStatusInterval)
    })
    const getUserPhoneWithCode = async () => {
      let res = await payApis.getUserPhoneWithRecommendCode({
        code: cState.recommendCode
      })
      if (res.success) {
        cState.isRecommandPay = true
        userApi.recordPv({code: cState.recommendCode})
        cState.recommendPayInfo.phone = res.result
      } else {
        // 获取不到对应的用户信息.无效推荐码
        cState.isRecommandPay = false
      }
    }
    const fetchSalesSetting = async () => {
      let res = await payApis.getSaleSetting({
        IsTest: process.env.VUE_APP_MODE !== 'prod'
      })
      if (res.success) {
        saleMap = {}
        let lists: SalesSetting[] = res.result
        saleAllSettings = res.result.concat([])
        const address: (string | undefined)[] = [];
        lists.map((item: SalesSetting) => {
          address.push(item.area)
          if (saleMap[item.area] === undefined) {
            saleMap[item.area] = [];
          }
          saleMap[item.area].push(item);
        })
        cState.areaList = _.uniq(address)

        cState.area = cState.areaList[0]
        cState.saleSettings = saleMap[cState.area]
        saleSettingChanged(saleMap[cState.area][0])
      }
    }
    const areaChanged = (item) => {
      cState.area = item
      // 切换套餐
      cState.saleSettings = saleMap[cState.area]
      saleSettingChanged(cState.saleSettings[0])
    }
    const vipTimeCompute = (timeSpan: string) => {
      const day: string = timeSpan.split('.')[0];
      const time = timeSpan.split('.')[1];
      const hour: string = time.split(':')[0];
      const minute: string = time.split(':')[1];
      const second: string = time.split(':')[2];
      const nowTimestamp = moment()
      const oldMoment = !userInfo.isVip ? nowTimestamp : moment(userInfo.vipDueTime, "YYYY-MM-DD HH:mm:ss")
      const timestamp = parseInt(day) * 86400000 + parseInt(hour) * 3600000 + parseInt(minute) * 60000 + parseInt(second) * 1000;
      const newMoment = oldMoment.add(timestamp, 'ms')
      if (cState.isRecommandPay || cState.recommendCodeUser) {
        cState.vipNewTime = newMoment.add(1, 'month').format('YYYY年MM月DD日')
      } else {
        cState.vipNewTime = newMoment.format('YYYY年MM月DD日')
      }

      //const timestamp = day * 86400000 + hour * 3600000 + minute * 60000 + second * 1000;
    }
    const saleSettingChanged = (item: SalesSetting) => {
      cState.saleSetting = item.id
      // 切换价格
      cState.payPrice = item.realPrice
      let tp = isNaN(parseFloat(cState.payPrice)) ? 0 : parseFloat(cState.payPrice)
      let dp = isNaN(parseFloat(cState.couponPrice)) ? 0 : parseFloat(cState.couponPrice)
      cState.tradePrice = (tp - dp).toString()
      // 切换到期时间
      vipTimeCompute(item.timeSpan)
    }
    const getOrderStatus = async () => {
      clearInterval(orderStatusInterval)
      orderStatusInterval = setInterval(async () => {
        if (isLoadingOrderStatus) {
          return
        }
        isLoadingOrderStatus = true
        let res = await payApis.getOrderStatus({
          OrderId: cState.orderId
        })
        isLoadingOrderStatus = false
        if (res.success) {
          if (res.result.status == PayStatus.SUCEESS) {
            (getUserData as any)();
            clearInterval(orderStatusInterval)
            Toast.success('支付成功!')
          } else if (res.result.status == PayStatus.CLOSE && res.result.status == PayStatus.REFUND) {
            clearInterval(orderStatusInterval)
            Toast.success('支付失败!')
          }
        }
      }, 3 * 1000)
      clearTimeout(orderStatusTimer)
      orderStatusTimer = setTimeout(() => {
        clearInterval(orderStatusInterval)
      }, 30 * 60 * 1000)
    }
    const onWeixinPay = async () => {
      if (!cState.saleSetting) {
        Toast.fail('请选择套餐!');
        return
      }
      if (cState.agreement.length == 0) {
        Toast.fail('请同意使用条例!');
        return
      }
      const schema: PaySchema = {
        OpenId: userInfo.openId,
        PayModeType: 1,
        RecommendCode: cState.recommendCode,
        SubscriptionPackageId: cState.saleSetting,
        CouponCode: cState.couponCode,
        Token: localStorage.getItem('access_token').replace('Bearer ', '')
      }
      if (cState.isPayLoading) {
        return
      }
      cState.isPayLoading = true
      if (Utils.getBrowserInfo().mobile) {//判断是否是移动设备打开。browser代码在下面
        var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
        if (Utils.getBrowserInfo().isWeixin) {
          //在微信中打开
          let res = await payApis.payForSaleSettingWx(schema)
          cState.isPayLoading = false
          if (res.success) {
            cState.orderId = res.result.orderId
            getOrderStatus()
            weixinRes = {
              appId: res.result.parameter.appId,
              timeStamp: res.result.parameter.timeStamp,
              nonceStr: res.result.parameter.nonceStr,
              package: res.result.parameter.package,
              signType: res.result.parameter.signType,
              sign: res.result.parameter.paySign
            }
            weChartJSBridge()
          } else {
            Toast.fail('生成订单失败!')
          }
        } else {
          let res = await payApis.payForSaleSettingH5(schema)
          cState.isPayLoading = false
          if (res.success) {
            cState.orderId = res.result.orderId
            const url = new URL(window.location.href);
            let redirec_uri = encodeURIComponent(url.origin + '/paystatus?orderId=' + cState.orderId)
            window.location.href = res.result.mWebUrl + '&redirect_url=' + redirec_uri
          } else {
            Toast.fail('生成订单失败!')
          }
          //api/customer/payment/paymentH5
          //非微信浏览器打开
        }
      }
    }
    const onCheckRcode = async () => {
      if (cState.recommendCode == userInfo.recommendCode) {
        cState.inviteStatus = 'error';
        cState.inviteMsg = '无法使用本人的推荐码!';
        cState.recommendCodeUser = false
        return
      }
      let res = await payApis.findReferralByCode({
        code: cState.recommendCode
      })
      if (res.success) {
        if (res.result.userId) {
          cState.recommendCodeUser = true
        }
        cState.inviteStatus = 'success';
        cState.inviteMsg = '推荐人：' + res.result.name;
        if (cState.saleSetting) {
          // 选择了套餐
          let findIndex = saleAllSettings.findIndex((item) => {
            return item.id == cState.saleSetting
          })
          console.log(findIndex)
          if (findIndex != -1) {
            vipTimeCompute(saleAllSettings[findIndex]['timeSpan'])
          }
        }
      } else {
        cState.inviteStatus = 'error';
        cState.inviteMsg = '﹡' + res.result.error.message;
      }
    }
    const onCheckCcode = async () => {
      if (!cState.saleSetting) {
        Toast.fail('请选择至少一个套餐!')
        return
      }
      let res = await payApis.coupon({
        Code: cState.couponCode,
        Price: cState.payPrice
      })
      if (res.success) {
        cState.couponPrice = res.result.deductionAmount
        let tp = isNaN(parseFloat(cState.payPrice)) ? 0 : parseFloat(cState.payPrice)
        let dp = isNaN(parseFloat(cState.couponPrice)) ? 0 : parseFloat(cState.couponPrice)
        cState.tradePrice = (tp - dp).toString()
      } else {
        cState.tradePrice = cState.payPrice
        cState.couponMsg = '﹡' + res.result.error.message;
      }
    }
    const onShowAgreement = () => {
      cState.agreementModal = true
    }
    const onCloseAgreement = (v) => {
      if (v) {
        cState.agreementModal = false
        cState.agreement = ['1']
      } else {
        cState.agreementModal = false
      }

    }
    const onGoToRecommend = () => {
      router.push('/r')
    }
    const weChartJSBridge = () => {
      if (typeof WeixinJSBridge === 'undefined') { // WeixinJSBridge 在TS编译会报错，因为该对象只在微信浏览器中存在，在文件头部声明 declare let WeixinJSBridge: any 即可
        document.addEventListener('WeixinJSBridgeReady', () => {
          onBridgeReady(weixinRes)
        }, false)
      } else {
        onBridgeReady(weixinRes)
      }
    }
    const onBridgeReady = (res: WeiXinRes) => {
      console.log(res)
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
            appId: res.appId,
            // 公众号名称，由商户传入
            timeStamp: res.timeStamp,
            // 时间戳，自1970年以来的秒数
            nonceStr: res.nonceStr,
            // 随机串
            package: res.package,
            signType: res.signType,
            // 微信签名方式：
            paySign: res.sign
            // 微信签名
          },
          (res: any) => {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              Toast.success('支付成功!')
              router.push({
                path: '/paystatus',
                query: {
                  orderId: cState.orderId
                }
              })
            }
            if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              // 支付取消
              Toast.fail('支付取消!')
            }

            if (res.err_msg === 'get_brand_wcpay_request:fail') {
              // 支付失败
              Toast.fail('支付失败!')
            }
          })
    }
    const onAgreementChanged = () => {
      if (cState.agreement.length != 0) {
        cState.agreement = []
      } else {
        cState.agreement = ['1']
      }
    }
    const onDoExchangeCode = async ()=>{
      let res = await payApis.doExchangeCode({
        code:cState.exchangeCode
      })
      if(res.success){
        Toast.success('恭喜您,VIP兑换成功!')
        getUserData()
      }else{
        Toast.fail('兑换码失败!')
      }
    }
    return {
      ...toRefs(cState),
      userInfo,
      areaChanged,
      saleSettingChanged,
      onWeixinPay,
      onGoToRecommend,
      onCheckRcode,
      onCheckCcode,
      onCloseAgreement,
      onShowAgreement,
      onAgreementChanged,
      onDoExchangeCode
    }
  }
})
