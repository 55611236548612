import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Pay from '../views/Pay.vue'
import Home from '../views/Home.vue'
import Help from '../views/Help.vue'
import HelpIndex from '../views/Help/Index.vue'
import HelpFeedBack from '../views/Help/FeedBack.vue'
import HelpSearch from '../views/Help/Search.vue'
import HelpResult from '../views/Help/Result.vue'
import HelpVideo from '../views/Help/Video.vue'
import HelpTopic from '../views/Help/Topic.vue'
import HelpVideoList from '../views/Help/VideoList.vue'
import HelpTopicList from '../views/Help/TopicList.vue'
import Recommend from '../views/Recommend.vue'
import Login from '../views/Login.vue'
import Redirect from '../views/Redirect.vue'
import LoginCustom from '../views/LoginCustom.vue'
import PayStatus from "@/views/PayStatus.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: Home,
    meta:{
      title:'首页'
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    children:[{
      path: 'index',
      name: 'HelpIndex',
      component: HelpIndex,
      meta:{
        title:'帮助中心'
      }
    },{
      path: 'feedback',
      name: 'HelpFeedBack',
      component: HelpFeedBack,
      meta:{
        title:'问题反馈'
      }
    },{
      path: 'search',
      name: 'HelpSearch',
      component: HelpSearch,
      meta:{
        title:'搜索'
      }
    },{
      path: 'result',
      name: 'HelpResult',
      component: HelpResult,
      meta:{
        title:'搜索结果'
      }
    },{
      path: 'video/center',
      name: 'HelpVideoList',
      component: HelpVideoList,
      meta:{
        title:'帮助视频'
      }
    },
      {
        path: 'video/detail/:id',
        name: 'HelpVideo',
        component: HelpVideo,
        meta:{
          title:'视频详情'
        }
      },
      {
        path: 'topic/category/:id',
        name: 'HelpTopicList',
        component: HelpTopicList,
        meta:{
          title:'图文列表'
        }
      },
      {
        path: 'topic/detail/:id',
        name: 'HelpTopic',
        component: HelpTopic,
        meta:{
          title:'图文详情'
        }
      }
    ]
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/r',
  //   name: 'Recommend',
  //   component: Recommend,
  //   meta:{
  //     title:'推荐活动'
  //   }
  // },
  // {
  //   path: '/mr',
  //   name: 'RedirectTr',
  //   component: Redirect
  // },
  // {
  //   path: '/mr%E5%8F%82%E4%B8%8E%E6%B4%BB%E5%8A%A8%E5%9B%9ET%E9%80%80%E8%AE%A2',
  //   name: 'Redirect',
  //   component: Redirect
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title:'登录账号'
    }
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay,
    meta:{
      title:'支付购买'
    }
  },
  {
    path: '/paystatus',
    name: 'PayStatus',
    component: PayStatus,
    meta:{
      title:'支付结果'
    }
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from)=>{
  if(to.meta&&to.meta.title){
    document.title = to.meta.title+',四川建龙软件，建龙质量验收资料管理软件'
  }else{
    document.title = '四川建龙软件，建龙质量验收资料管理软件'
  }
})
export default router
