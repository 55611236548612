
import {defineComponent, onBeforeMount} from "@vue/runtime-core";
import {reactive, toRefs} from "@vue/reactivity";
import {Toast} from "vant";
import {onBeforeRouteUpdate, useRouter} from "vue-router";
import helpApis from "@/api/help";
import * as _ from 'lodash'
interface HelpTopicListState {
  topicList:{
    id:string,
    title:string,
    fixOnTop:boolean
  } []
  categoryId: string,
  isLoading:boolean
}
export default defineComponent({
  name: "Home",
  setup(){
    const router = useRouter();
    const cState = reactive<HelpTopicListState>({
      categoryId:'',
      topicList:[],
      isLoading:false
    })
    onBeforeMount(() => {
      let query = router.currentRoute.value.params
      if(!query.id){
        Toast.fail('分类不存在!')
      }else {
        cState.categoryId = query.id as string
        getTopicByCategoryId()
      }
    })
    onBeforeRouteUpdate((to) => {
      cState.categoryId = to.params.id as string
      getTopicByCategoryId()
    });
    const getTopicByCategoryId = async ()=>{
      cState.isLoading = true
      let res = await helpApis.getTopicList({
        categoryId:cState.categoryId
      })
      cState.isLoading = false
      if(res.success){
        cState.topicList = res.result
      }
    }
    const goToTopicDetail = (id)=>{
      router.push({
        path:'/help/topic/detail/'+id
      })
    }
    return {
      ...toRefs(cState),
      goToTopicDetail
    }
  }
})
