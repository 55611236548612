import http from '../../utils/http'
// get请求
interface captchaParam{
    phoneNumber?: string,
    captchaId?: string,
    captcha?: string,
    captchaType?: string
}
interface loginWithPhoneCodeParam{
    userNameOrEmailAddressOrPhoneNumber: string,
    captcha : string,
}
interface loginWithPasswordParam{
    userNameOrEmailAddressOrPhoneNumber: string,
    password : string,
}
export default{
    getAuthGetcaptcha(params: captchaParam): Promise<any>{
        return http.post(`/api/TokenAuth/Getcaptcha`,params)
    },
    loginWithPhoneCode(params: loginWithPhoneCodeParam): Promise<any>{
        return http.post(`/api/TokenAuth/LoginBySms`,params)
    },
    bindingWeiXinByLogin(params: any): Promise<any>{
        return http.post(`/api/app/wei-xin/binding-wei-xin-by-login`,params)
    },
    loginWithPassword(params: loginWithPasswordParam): Promise<any>{
        return http.post(`/api/TokenAuth/LoginComplex`,params)
    },
    getCaptchaImg(params:any): Promise<any>{
        return http.get(`/api/TokenAuth/GetCaptcha`,params)
    }
}
