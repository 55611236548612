import http from '../../utils/http'
export default{
    getTopicCategoryInfo(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/mobileCategories`,params)
    },
    //
    uploadFeedbackFile(params: any): Promise<any>{
    return http.upload(`/api/app/Topic/UploadVideo`,params,process.env["VUE_APP_BACKEND_URL"])
    },
    addFeedback(params: any): Promise<any>{
        return http.post(`/api/customer/helpCenter/feedBack`, params)
    },
    getVideoCategoryInfo(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/homeVideoDisplayCategories`,params)
    },
    getVideoList(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/homeVideoTopics/${params.categoryId}`,params)
    },
    getTopicList(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/topicByCategoryId/${params.categoryId}`,params)
    },
    getTopKeywordList(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/homeTopKeywords`,params)
    },
    getTopicsByKeyWordId(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/topicsByKeyWord/${params.keywordId}`,params)
    },
    getTopicsByKeyWord(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/topicsByKeyWord`,params)
    },
    getTopicsInfoById(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/topicDetail/${params.id}`, {})
    },
    getTopicsKeyWord(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/keyWordByTopic/${params.id}`, {})
    },
    getTopicsRelation(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/bingdingTopicById/${params.id}`, {})
    },
    addVideoPlayCount(params: any): Promise<any>{
    return http.post(`/api/customer/helpCenter/browseVideo/${params.id}`, {})
    },
    getVideoCategories(params: any): Promise<any>{
        return http.get(`/api/customer/helpCenter/videoCategories`, {})
    },
    getVideoByCategoryId(params: any): Promise<any>{
        return http.post(`/api/customer/helpCenter/searchVideos`, params)
    }

}
