<template>
  <div class="hs-container">
    <div class="hs-top">
      <div class="search-c">
        <div class="hs-search-close">
          <van-icon name="cross" @click="goBack()" />
        </div>
        <div class="hs-top-left">
          <van-field
              v-model="searchValue"
              label=""
              left-icon="search"
              ref="searchInput"
              placeholder="请输入关键词"
              center
              :autofocus="true"
          >
          </van-field>
        </div>
        <div class="hs-top-right">
          <van-button size="small" type="default" @click="searchTopic()">搜索</van-button>
        </div>
      </div>
    </div>
    <div class="hs-bottom">
      <div class="hs-custom-c">
        <div class="hs-items-c" v-if="keywordList.length!=0">
          <div class="hs-items-title">
            关键词推荐
          </div>
          <div class="hs-items">
            <div class="hs-item" v-for="(item,index) of keywordList" :key="index" @click="searchTopic(item.text,item.id)">
              {{item.text}}
            </div>
          </div>
        </div>
        <div class="hs-items-c" v-if="searchList.length!=0">
          <div class="hs-items-title">
            搜索历史
            <span @click="clearSearchList()">清空</span>
          </div>
          <div class="hs-items">
            <div class="hs-item" v-for="(item,index) of searchList" :key="index" @click="searchTopic(item)">
              {{item}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, inject, onBeforeMount, onMounted, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {reactive, toRefs} from "@vue/reactivity";
import {nextTick} from 'vue'
import helpApis from "@/api/help";
import {Toast} from "vant"
import * as _ from 'lodash'

interface HelpSearchState {
  searchValue: string,
  searchList:string[],
  keywordList:{
    id:string,
    text:string
  }[]
}

export default defineComponent(
    {
      name: "HelpIndex",
      setup() {
        const router = useRouter();
        const searchInput = ref(null)
        const addTextToSearchList = inject('addTextToSearchList')
        const getSearchList = inject('getSearchList')
        const cState = reactive<HelpSearchState>({
          searchValue: '',
          searchList:[],
          keywordList:[]
        })
        onBeforeMount(() => {
          //window.location.href = 'https://cb32.com'
          //
          let query = router.currentRoute.value.query
          if(query.keyword){
            cState.searchValue = decodeURIComponent(query.keyword as string)
          }
        })
        onMounted(() => {
          getTopKeywordList()
          cState.searchList = (getSearchList as any)()
          searchInput.value.focus()
        })
        const getTopKeywordList = async ()=>{
          let res = await helpApis.getTopKeywordList({})
          if(res.success){
            cState.keywordList = res.result
          }
        }
        const goBack = _.throttle(()=>{
          router.go(-1)
        },1000)
        const clearSearchList = ()=>{
          localStorage.removeItem('search_list')
          cState.searchList = []
        }
        const searchTopic = (text,id)=>{
          if(!text){
            text = cState.searchValue
          }

          let extraParams = {}
          if(id){
            extraParams['keywordId'] = id
          }
          if(!text) {
            Toast.fail('请输入关键词!')
          }else{
            cState.searchList = (addTextToSearchList as any)(text)
            router.replace({
              path:'/help/result',
              query:{
                keyword: encodeURIComponent(text),
                ...extraParams
              }
            })
          }

        }
        return {
          ...toRefs(cState),
          searchInput,
          goBack,
          clearSearchList,
          searchTopic
        }
      }
    }
)
</script>

<style scoped lang="scss">
.hs-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .hs-top {
    height: 58px;
    width: 100%;
    padding: 12px;
    background-color: #f84e00;
    display: flex;
    align-items: center;

    .search-c {
      height: 28px;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .hs-search-close{
      width: 40px;
      color: white;
    }
    .hs-top-left {
      flex: 1;
      height: 100%;

      .van-cell {
        height: 100%;
        padding: 0px 12px;
        border-radius: 4px;
      }
    }

    .hs-top-right {
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .van-button {
        background-color: #f84e00;
        height: 100%;
        border: none;
        color: white;
        font-size: 14px;
      }
    }
  }

  .hs-bottom {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    background-color: #e5e5e5;
    .hs-custom-c{
      background-color: white;
      height: 100%;
      .hs-items-c{
        .hs-items-title{
          font-size: 16px;
          color: #333333;
          padding: 15px;
          font-family: "Noto Sans SC";
          font-weight: 500;
          position: relative;
          span{
            position: absolute;
            right: 20px;
            font-weight: 400;
            font-size: 15px;
            color: #f84e00;
          }
        }
        .hs-items{
          display: flex;
          padding: 0px 15px;
          flex-wrap: wrap;
          .hs-item{
            background-color: #e5e5e5;
            border-radius: 2px;
            padding: 3px 8px;
            font-size: 14px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

</style>
